import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import { receiptCurrency } from "src/helper/currency";

const Header = ({ grandTotal }) => {
  const whatsapp = "+62 831-3528-8886";
  const transaction = useSelector((state) => state.transaction);
  const store = JSON.parse(localStorage.getItem("store"));
  return (
    <>
      <div className="icon-head text-center">
        <Link to="#">
          <ImageWithBasePath
            src="assets/img/daunlogo.png"
            width={130}
            height={50}
            alt="Receipt Logo"
          />
        </Link>
      </div>
      <div className="text-center info text-center">
        <div className="mb-2 small-font">{store.store_name}</div>
        <div className="address small-font mb-2">
          {store.store_address}
          {/* {transaction.detailTransaction.warehouseAddress} */}
        </div>
        <div className="contact" style={{ color: "black" }}>
          Cell/Whatsapp : {whatsapp}
        </div>

        <div className="d-flex border border-container border-dark bg-transparent rounded w-100 my-2 total-box">
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <div className="total-label">Total</div>
            <div className="fw-bolder total-price">
              {receiptCurrency.format(grandTotal).replace(".", ",")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
