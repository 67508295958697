import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "../modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  Minus,
  MinusCircle,
  Plus,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  setModalQtySelect,
  resetReturProduct,
  setModalNewItem,
  incrementQuantity,
  decrementQuantity,
  setModalExchangeSummary,
} from "src/redux/store/transaction";
import {
  calculateDiscount,
  receiptCurrency,
  rupiahCurrency,
} from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./BackButton";
import Select from "react-select";
import SearchProduct from "./SearchProduct";

const Item = ({ item }) => {
  const dispatch = useDispatch();
  const handleIncrement = () => {
    dispatch(
      incrementQuantity({
        product_code: item.product_code,
        product_discount: item.product_discount,
        product_price: item.product_price,
      })
    );
  };
  const handleDecrement = () => {
    dispatch(
      decrementQuantity({
        product_code: item.product_code,
        product_discount: item.product_discount,
        product_price: item.product_price,
      })
    );
  };
  return (
    <div className="d-flex justify-content-between align-items-center gap-4">
      <div>
        <div className="fw-bold user-select-none">{item.product_code}</div>
        <div className="text-muted user-select-none">
          <small>{item.product_name}</small>
        </div>
        <div className="text-muted user-select-none">
          <small>
            {item.product_alias} | ({" "}
            <span className="fw-bold">{item.product_size}</span> )
          </small>
        </div>
        {/* <div className="text-muted user-select-none">
            <span className="fw-bold">{item.size}</span>{" "}
        </div>
        <div className="d-flex align-items-start gap-2">
            <div className="d-flex flex-column">
            <div className="fw-bolder">
                {rupiahCurrency.format(
                calculateDiscount(item.price, item.discount).afterDisc
                )}
            </div>
            {item.discount > 0 && <del>{rupiahCurrency.format(item.price)}</del>}
            </div>
            {item.discount > 0 ? (
            <div className="badge bg-secondary" style={{ fontSize: "10px" }}>
                {item.discount} % Off
            </div>
            ) : null}
        </div> */}
      </div>

      <div className="d-flex gap-2 align-items-center">
        <div
          className="retur-cart-button bg-outline-secondary"
          onClick={handleDecrement}
        >
          <Minus width={20} />
        </div>
        <div className="retur-cart-qty">{item.qty}</div>
        <div
          className="retur-cart-button bg-outline-secondary"
          onClick={handleIncrement}
        >
          <Plus width={20} />
        </div>
      </div>
    </div>
  );
};

const NewItems = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleConfirmRetur = (e) => {
    e.preventDefault();
    dispatch(closeModalTransaction());
    dispatch(toggleModalCompletedRetur());
  };

  const handleBackProductSelect = () => {
    dispatch(setModalProductSelect(true));
    dispatch(setModalQtySelect(false));
    dispatch(resetReturProduct());
  };

  const handleAddItem = () => {
    dispatch(setModalProductSelect(true));
    dispatch(setModalNewItem(false));
  };

  const handleExchangeSummary = () => {
    dispatch(setModalExchangeSummary(true));
    dispatch(setModalNewItem(false));
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalNewItem}
      //   onHide={handleBackProductSelect}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <BackButton handleClick={handleBackProductSelect} />
      <Modal.Body>
        <div className="px-3 mt-5">
          <h4 className="mb-2">New Items</h4>
          <div className="d-flex flex-column gap-3 retur-cart">
            {transaction.returCart.map((item, key) => (
              <Item key={key} item={item} />
            ))}
          </div>
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          <button
            type="button"
            className="btn btn-outline-secondary flex-fill"
            onClick={handleAddItem}
          >
            Add more items
          </button>
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          <button
            disabled={transaction.returCart.length === 0}
            type="button"
            className="btn btn-secondary flex-fill"
            onClick={handleExchangeSummary}
          >
            Next
          </button>
        </div>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default NewItems;
