// src/features/warehouseSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
  warehouseList: [],
};

export const getWarehouse = createAsyncThunk("warehouse", async (params) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const store = JSON.parse(localStorage.getItem("store"));
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/warehouse`,
      {
        warehouse_id: store.store,
        warehouse_name: "",
        page: 1,
        limit: 0,
        order: "warehouse_name",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = response.data;
    return {
      data: result.data.data,
      total: result.data.total,
      params,
    };
  } catch (error) {
    console.log(error);
  }
});

export const getWarehouseList = createAsyncThunk(
  "warehouse-list",
  async (params) => {
    // const user = JSON.parse(localStorage.getItem("userData"));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/warehouse-list`
      );
      const result = response.data.data.data;
      return {
        data: result,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getWarehouseGoods = createAsyncThunk(
  "warehouse-goods",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/warehouse-goods`,
        {
          warehouse_id: store.store,
          warehouse_name: "",
          page: 1,
          limit: 0,
          order: "warehouse_name",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      return {
        data: result.data.data,
        total: result.data.total,
        params,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWarehouse.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
    });
    builder.addCase(getWarehouse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWarehouse.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getWarehouseGoods.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
    });
    builder.addCase(getWarehouseGoods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWarehouseGoods.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getWarehouseList.fulfilled, (state, action) => {
      state.warehouseList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getWarehouseList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWarehouseList.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default warehouseSlice.reducer;
