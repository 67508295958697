// hooks/useEpsonPrinter.js
import { useEffect, useState } from "react";

const useEpsonPrinter = () => {
  const [printer, setPrinter] = useState(null);

  const PRINTER_IPADDRESS = `198.168.198.168`;

  useEffect(() => {
    const device = new window.epson.ePOSDevice();
    device.connect(PRINTER_IPADDRESS, 8008, (status) => {
      if (status === "OK") {
        console.log("Connected to printer!");
        setupPrinter(device);
      } else {
        console.error("Printer connection failed:", status);
      }
    });
  }, [PRINTER_IPADDRESS]);

  const setupPrinter = (device) => {
    device.createDevice(
      "local_printer_id",
      window.ePOSDevice.DEVICE_TYPE_PRINTER,
      { crypto: false, buffer: false },
      (printer) => {
        if (printer) {
          console.log("Printer setup completed");
          setPrinter(printer);
        } else {
          console.error("Printer setup failed");
        }
      }
    );
  };

  return printer;
};

export default useEpsonPrinter;
