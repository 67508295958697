import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Archive,
  Calendar,
  User,
  Trash2,
  Edit,
  Filter,
  Sliders,
  Printer,
  MinusCircle,
  PlusCircle,
  Trash,
  AlertTriangle,
} from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable-stock-opname";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getTransferStockOut } from "src/redux/store/transferStockOut";
import Header from "./Header";
import "./style.css";
import {
  changeStock,
  closeToastNotification,
  deleteStockOpnameDetail,
  draftStatusStockOpname,
  getProductList,
  getStockOpnameDetail,
  removeProduct,
  scanProductOut,
  setNoteSo,
  updateStatusStockOpname,
  updateStock,
  updateStockOpname,
  updateStockOpnameDetail,
} from "src/redux/store/stockopname";
import { Button, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import NotFound from "./NotFound";
// import Header from "./Header";
// import AddModal from "./AddModal";

const StockOpnameCreate = () => {
  //   const data = useSelector((state) => state.stocktransferdata);
  const { soID } = useParams();
  const navigate = useNavigate();
  const stock = useSelector((state) => state.stockOpname);
  const users = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(5);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [searchCode, setSearchCode] = useState("");
  const MySwal = withReactContent(Swal);
  const user = JSON.parse(localStorage.getItem("userData"));
  const store = JSON.parse(localStorage.getItem("store"));
  const [created, setCreated] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    dispatch(getProductList());
    dispatch(getStockOpnameDetail({ stock_opname_id: soID, table: false }));
  }, [dispatch, soID]);

  const handleButtonQty = (type, product_code, current_qty) => {
    // dispatch(
    //   updateStock({
    //     product_code: product_code,
    //     type,
    //     current_qty,
    //     created_by: created.value,
    //   })
    // );
    if (created.value) {
      dispatch(
        updateStockOpnameDetail({
          product_code: product_code,
          type,
          current_qty,
          created_by: created.value,
          method: "button",
        })
      );
    } else {
      alert("Please select the staff first");
    }
  };

  const handleChangeQty = (event, type, product_code, current_qty) => {
    // dispatch(
    //   updateStock({
    //     product_code: product_code,
    //     type,
    //     current_qty,
    //     created_by: created.value,
    //   })
    // );
    console.log("current qty ", current_qty);
    if (created.value) {
      // if (current_qty) {
      dispatch(
        updateStockOpnameDetail({
          product_code: product_code,
          type,
          current_qty,
          created_by: created.value,
          method: "input",
        })
      );
      // }
    } else {
      event.preventDefault();
      alert("Please select the staff first");
    }
  };

  const renderTooltopMismatch = (props, diff) => {
    // console.log(props);
    return (
      <Tooltip id="qty-tooltop" {...props}>
        Missmatch
      </Tooltip>
    );
  };

  const renderTooltopOver = (props, diff) => {
    // console.log(props);
    return (
      <Tooltip id="qty-tooltop" {...props}>
        Over
      </Tooltip>
    );
  };

  const handleDelete = (item) => {
    MySwal.fire({
      title: `Delete this record? <br/><code>${item.product_code}</code>`,
      text: "",
      showCancelButton: true,
      icon: "warning",
      // cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteStockOpnameDetail({ product_code: item.product_code }));
      } else {
        // console.log("canceled");
      }
    });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
      width: 50,
      minWidth: 50,
    },
    {
      title: "Product Code",
      dataIndex: "product_code",
      width: 150,
      minWidth: 150,
      ellipsis: true,
    },
    {
      title: "Product Name",
      dataIndex: "product_alias",
      ellipsis: true,
    },
    {
      title: "Size",
      dataIndex: "product_size",
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: "QTY",
      dataIndex: "qty",
      width: 200,
      minWidth: 200,
      render: (value, item, index) => {
        return (
          <div
            className="d-flex align-items-center gap-2 py-1"
            style={{ userSelect: "none" }}
          >
            <MinusCircle
              width={80}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleButtonQty("minus", item.product_code, item?.qty)
              }
            />
            <input
              type="text"
              className="form-control text-center"
              // min={0}
              value={item?.qty}
              onChange={(e) =>
                handleChangeQty(e, "", item.product_code, e.target.value)
              }
            />
            <PlusCircle
              width={80}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleButtonQty("plus", item.product_code, item?.qty)
              }
            />
          </div>
        );
      },
    },
    // {
    //   title: "System",
    //   dataIndex: "stock",
    //   width: 100,
    //   minWidth: 100,
    //   render: (value, item, index) => {
    //     return (
    //       <div className="d-flex align-items-center gap-2">
    //         <span
    //           className={`${item.stock < 0 ? `text-danger` : `text-success`}`}
    //         >
    //           {item.stock}
    //         </span>
    //         {/* {item.stock >= item.qty && (
    //           <AlertTriangle className="text-danger" width={10} />
    //         )} */}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Diff",
    //   dataIndex: "stock",
    //   width: 80,
    //   minWidth: 80,
    //   render: (value, item, index) => {
    //     let diff = 0;
    //     if (item.stock < 0) {
    //       diff = item.stock + item.qty;
    //     } else {
    //       diff = item.qty - item.stock;
    //     }
    //     return (
    //       <div className="d-flex align-items-center justify-content-center gap-2 position-relative">
    //         {diff < 0 && <div className={`text-danger`}>{diff}</div>}
    //         {diff == 0 && <div>0</div>}
    //         {diff > 0 && <div className={`text-success`}>{diff}</div>}
    //         {diff < 0 && (
    //           <OverlayTrigger
    //             placement="top"
    //             overlay={renderTooltopMismatch}
    //             diff={diff}
    //           >
    //             <AlertTriangle className="text-danger" width={15} />
    //           </OverlayTrigger>
    //         )}
    //         {diff > 0 && (
    //           <OverlayTrigger
    //             placement="top"
    //             overlay={renderTooltopOver}
    //             diff={diff}
    //           >
    //             <AlertTriangle className="text-success" width={15} />
    //           </OverlayTrigger>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      minWidth: 80,
      render: (value, item, index) => {
        return (
          <div
            className="d-flex align-items-center gap-2 py-1 text-danger"
            style={{ userSelect: "none" }}
          >
            <Trash
              width={80}
              style={{ cursor: "pointer" }}
              onClick={
                () => handleDelete(item)
                // () =>
                //   dispatch(
                //     deleteStockOpnameDetail({ product_code: item.product_code })
                //   )
                // dispatch(removeProduct({ product_code: item.product_code }))
              }
            />
          </div>
        );
      },
    },
  ];

  const columnsView = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
      width: 50,
      minWidth: 50,
    },
    {
      title: "Product Code",
      dataIndex: "product_code",
      ellipsis: true,
    },
    {
      title: "Product Name",
      dataIndex: "product_alias",
      ellipsis: true,
    },
    {
      title: "Size",
      dataIndex: "product_size",
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: "QTY",
      dataIndex: "qty",
      width: 200,
      minWidth: 200,
      render: (value, item, index) => {
        return (
          <div
            className="d-flex align-items-center gap-2 py-1"
            style={{ userSelect: "none" }}
          >
            <input
              type="text"
              readOnly
              className="form-control text-center"
              disabled
              defaultValue={item?.qty}
            />
          </div>
        );
      },
    },
    {
      title: "System",
      dataIndex: "stock",
      width: 100,
      minWidth: 100,
      render: (value, item, index) => {
        return (
          <div className="d-flex align-items-center gap-2">
            <span
              className={`${item.stock < 0 ? `text-danger` : `text-success`}`}
            >
              {item.stock}
            </span>
            {/* {item.stock >= item.qty && (
              <AlertTriangle className="text-danger" width={10} />
            )} */}
          </div>
        );
      },
    },
    {
      title: "Diff",
      dataIndex: "stock",
      width: 80,
      minWidth: 80,
      render: (value, item, index) => {
        let diff = 0;
        if (item.stock < 0) {
          diff = item.stock + item.qty;
        } else {
          diff = item.qty - item.stock;
        }
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 position-relative">
            {diff < 0 && <div className={`text-danger`}>{diff}</div>}
            {diff == 0 && <div>0</div>}
            {diff > 0 && <div className={`text-success`}>{diff}</div>}
            {diff < 0 && (
              <OverlayTrigger
                placement="top"
                overlay={renderTooltopMismatch}
                diff={diff}
              >
                <AlertTriangle className="text-danger" width={15} />
              </OverlayTrigger>
            )}
            {diff > 0 && (
              <OverlayTrigger
                placement="top"
                overlay={renderTooltopOver}
                diff={diff}
              >
                <AlertTriangle className="text-success" width={15} />
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
  ];

  const searchProductCode = (e) => {
    e.preventDefault();
    if (created.value) {
      dispatch(
        scanProductOut({
          txtProductCode: searchCode,
          txtWarehouseId: store.store,
          created_by: created.value,
        })
      );
    } else {
      alert("Please select the staff first");
    }
    setSearchCode("");
  };

  const handleCloseToast = () => {
    dispatch(closeToastNotification());
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(closeToastNotification());
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [dispatch, stock.searchResultToast]);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Submit these changes?",
      text: "",
      showCancelButton: true,
      icon: "question",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // const product = stock.detail.data.map((item) => {
          //   return {
          //     product_code: item.product_code,
          //     product_size: item.product_size,
          //     so_qty: item.qty,
          //   };
          // });
          if (created.value) {
            const res = await dispatch(
              updateStatusStockOpname({
                stock_opname_id: soID,
                updated_by: created.value,
                so_note: stock.detail.noteSo,

                // so_product: product,
              })
            ).unwrap();
            navigate("/stock-opname");
          } else {
            alert("Please select the staff first");
          }
        } catch (error) {
          alert(error);
        }
      } else {
        // console.log("canceled");
      }
    });
  };

  const showDraftAlert = () => {
    MySwal.fire({
      title: "Save as draft?",
      text: "",
      showCancelButton: true,
      icon: "question",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const product = stock.detail.data.map((item) => {
            return {
              product_code: item.product_code,
              product_size: item.product_size,
              so_qty: item.qty,
            };
          });
          const res = await dispatch(
            draftStatusStockOpname({
              stock_opname_id: soID,
              updated_by: user.id,
              so_note: stock.detail.noteSo,
              so_product: product,
            })
          ).unwrap();
          navigate("/stock-opname");
        } catch (error) {
          alert(error);
        }
      } else {
        // console.log("canceled");
      }
    });
  };

  return !stock.loadingSO ? (
    stock.detail.notFound ? (
      <NotFound />
    ) : (
      <div className="page-wrapper">
        <div className="content">
          <Header />
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="stock-opname-container">
                <div className="stock-opname-header">
                  <h3>{stock.detail.warehouseName}</h3>
                  {stock.detail.status == "Draft" && (
                    <div className="stock-opname-input">
                      <form onSubmit={searchProductCode}>
                        <div className="search-input">
                          <input
                            type="text"
                            placeholder="Scan or search by product code"
                            className="form-control"
                            value={searchCode}
                            onChange={(e) => setSearchCode(e.target.value)}
                          />
                          <button
                            className="btn btn-searchset btn-searchset-custom"
                            type="submit"
                          ></button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>

                <div className="stock-opname-content mt-3">
                  <Table
                    tableClassName={"tight-table"}
                    className="table datanew"
                    columns={
                      stock.detail.status == "Draft" ? columns : columnsView
                    }
                    dataSource={stock.detail.data}
                    loading={stock.loadingTableSO}
                  />
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <div className="d-inline">
                    <h4>
                      Total Qty :{" "}
                      {stock.detail.data.reduce(
                        (acc, item) => acc + item.qty,
                        0
                      )}
                    </h4>
                  </div>
                </div>

                {stock.detail.data.length > 0 && (
                  <div className="mt-3">
                    <label className="form-label">Note SO</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add note"
                      readOnly={stock.detail.status != "Draft"}
                      value={stock.detail.noteSo}
                      onChange={(e) => dispatch(setNoteSo(e.target.value))}
                    />
                  </div>
                )}

                {stock.detail.status == "Draft" && (
                  <div className="d-flex justify-content-end align-items-end gap-3 mt-3">
                    <div className="" style={{ minWidth: "200px" }}>
                      <label>Created By</label>
                      <Select
                        classNamePrefix="react-select"
                        options={users.listAbsen
                          .filter((item) => item.check_out === null)
                          .map((item, key) => {
                            return {
                              value: item.user_id,
                              label: item?.user?.name,
                            };
                          })}
                        value={created}
                        onChange={(e) => setCreated(e)}
                      />
                      {/* </li>
                </ul> */}
                    </div>
                    {/* <button
                      disabled={stock.detail.data.length == 0}
                      onClick={showDraftAlert}
                      className="btn btn-submit"
                    >
                      Save as Draft
                    </button> */}
                    <button
                      disabled={stock.detail.data.length == 0}
                      className="btn btn-secondary"
                      onClick={showConfirmationAlert}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
        <div className="toast-container position-fixed top-0 end-0 p-3">
          <Toast
            show={stock.searchResultToast}
            onClose={handleCloseToast}
            id="solidDangerToast"
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <Toast.Header closeButton className="text-default">
              <strong className="me-auto">
                {stock.searchResult ? `Success` : `Failed`}
              </strong>
            </Toast.Header>
            <Toast.Body>{stock.searchMessage}</Toast.Body>
          </Toast>
        </div>
        {/* <AddModal /> */}
      </div>
    )
  ) : (
    <div className="page-wrapper">
      <div className="content">
        <p>Loading data..</p>
      </div>
    </div>
  );
};

export default StockOpnameCreate;
