import React from "react";
import "../modal.css";
import { CornerUpLeft } from "react-feather";

const BackButton = ({ handleClick, relative = false }) => {
  return (
    <div
      className={`${
        relative ? `modal-back-button-relative` : `modal-back-button`
      }`}
      onClick={handleClick}
    >
      <CornerUpLeft size={20} />
    </div>
  );
};

export default BackButton;
