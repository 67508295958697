import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "src/core/img/imagewithbasebath";

const Header = () => {
  const whatsapp = "+62 831-3528-8886";
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const store = JSON.parse(localStorage.getItem("store"));
  return (
    <>
      <div className="icon-head text-center">
        <Link to="#">
          <ImageWithBasePath
            src="assets/img/daunlogo.png"
            width={130}
            height={50}
            alt="Receipt Logo"
          />
        </Link>
      </div>
      <div className="text-center info text-center">
        <div className="mb-2 fs-4 fw-bold medium-font">{store.store_name}</div>
        <div className="address mb-2">
          {transferStockOut.detail.warehouseAddress}
        </div>
      </div>
    </>
  );
};

export default Header;
