// src/features/searchProductSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import Swal from "sweetalert2";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,
};

export const getSearchProduct = createAsyncThunk(
  "product/stock",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `/v1/product/stock`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            txtWarehouseId: store.store,
            ...params,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data.data;
      return {
        data: result.data,
        total: result.total,
        params,
      };
      // return result;
    } catch (error) {
      console.log(error);
    }
  }
);

const searchProductSlice = createSlice({
  name: "searchProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchProduct.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getSearchProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSearchProduct.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  toggleModalAdd,
  toggleModalPrint,
  addProduct,
  removeProduct,
  setDate,
  setNote,
  setStore,
  setItemNote,
  setItemNoteDetail,
  setItemQtyDetail,
  setItemQty,
  clearDetail,
  reset,
} = searchProductSlice.actions;
export default searchProductSlice.reducer;
