import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalPrint } from "src/redux/store/transferStockIn";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Printer } from "react-feather";
import { Divider } from "antd";
import { printLetter } from "src/helper/print";

const PrintModal = () => {
  const transferStockIn = useSelector((state) => state.transferStockIn);
  const dispatch = useDispatch();

  const handlePrint = () => {
    printLetter("skb");
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockIn.modalPrint}
      onHide={() => dispatch(toggleModalPrint())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Preview</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body id="skb" className="p-5">
        <div className="print-receipts-wrapper">
          {/* <div className="row my-3">
            <div className="col-6">
              <div className="d-flex flex-column gap-2">
                <div>No: [no_ref]</div>
                <div>Field: [field_name]</div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column gap-2">
                <div>No: [no_ref]</div>
                <div>Field: [field_name]</div>
              </div>
            </div>
          </div> */}
          {/* <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Size</th>
                  <th>Qty</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {transferStockIn.detail.data.map((item, key) => (
                  <tr key={key}>
                    <td className="padding-fix">{key + 1}</td>
                    <td></td>
                    <td className="padding-fix">{item.product_alias}</td>
                    <td></td>
                    <td>{item.qty}</td>
                    <td>{item.stock_order_detail_note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          <div className="text-center" style={{ marginBottom: "40px" }}>
            <div className="text-center fs-4 fw-bolder">Incoming Goods</div>
            <small>{transferStockIn.detail.stock_order_code}</small>
          </div>

          <table className="w-100 table-fit">
            <thead>
              <tr style={{ borderBottom: "1px solid #DDD" }}>
                <th
                  style={{
                    width: "50%",
                    paddingBottom: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Items
                </th>
                {/* <th
                  style={{
                    width: "10%",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    width: "10%",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Qty Outstanding
                </th> */}
                <th
                  className="text-center"
                  style={{
                    width: "10%",
                    paddingBottom: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Qty Received
                </th>
                <th
                  style={{
                    width: "20%",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Notes
                </th>
              </tr>
            </thead>
            <tbody className="content-row">
              {transferStockIn.detail.data.map((item, key) => (
                <tr
                  key={key}
                  className={`${
                    key != transferStockIn.detail.data.length - 1
                      ? `border-bottom-dashed`
                      : ``
                  }`}
                >
                  <td className="py-2">
                    {/* <div className="fw-bold">{item.product_name}</div> */}
                    <div className="fw-light">
                      {item.product_alias} {item.product_size}
                    </div>
                    {/* <div>{item.product_size}</div> */}
                    <div>{item.product_code}</div>
                  </td>
                  <td
                    className="text-center fw-bold fs-6"
                    style={{ paddingRight: "15px" }}
                  >
                    {/* {item.qty_reference} */}
                    {item.qty}
                  </td>
                  {/* <td
                    className="text-center fw-bold fs-6"
                    style={{ paddingRight: "15px" }}
                  >
                    {item.qty ? item.qty : 0}
                  </td> */}
                  {/* <td
                    className="text-center fw-bold fs-6"
                    style={{ paddingRight: "15px" }}
                  >
                    {item.qty_outstanding ? item.qty_outstanding : 0}
                  </td> */}
                  {/* <td
                    className="text-center fw-bold fs-6"
                    style={{ paddingRight: "15px" }}
                  >
                    {item.qty_outstanding ? item.qty_outstanding : 0}
                  </td> */}
                  {/* <td
                    className="text-center fw-bold fs-6"
                    style={{ paddingRight: "15px" }}
                  >
                    {item.qty ? item.qty : 0}
                  </td> */}
                  <td className="text-center">
                    {item.stock_order_detail_note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-start mt-4 gap-3">
            <div className="d-inline-block">
              <div>
                <small>
                  Created by :{" "}
                  <strong>{transferStockIn.detail.created_by}</strong>
                </small>
              </div>
              <div>
                <small>
                  Created at :{" "}
                  <strong>{transferStockIn.detail.stock_order_date}</strong>
                </small>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-2 fs-6">
              <div className="fw-bolder">Total :</div>
              <div>
                {transferStockIn.detail.data.reduce(
                  (accumulator, item) => accumulator + parseInt(item.qty),
                  0
                )}{" "}
                products
              </div>
            </div>
          </div>

          <div className="my-5">
            <label className="form-label">Notes</label>
            <div className="print-receipts-note">
              {transferStockIn.detail.stock_order_note}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-secondary"
            onClick={() => dispatch(toggleModalPrint())}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintModal;
