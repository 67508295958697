import React from "react";
import { calculateVAT, receiptCurrency } from "src/helper/currency";
import "./style.css";
import Footer from "../Footer";
import Header from "../Header";

const PrintableComponent = ({ pos }) => {
  return pos.loadingDetailTransaction ? (
    <p className="text-center my-3">Loading data...</p>
  ) : (
    <div className="printable-component p-4" id="reprint-receipt">
      <Header grandTotal={pos.detailTransaction.billingGrandTotal} />

      {pos.detailTransaction.billingRetur.length ? (
        <div className="my-3">
          <div>Retur Item(s)</div>
          <table className="w-100 table-fit">
            <thead>
              <tr>
                <th style={{ width: "80%" }}>Items</th>
                <th className="text-end"></th>
                {/* <th>Qty</th>
            <th className="text-end">Total</th> */}
              </tr>
            </thead>
            <tbody className="content-row">
              {pos.detailTransaction.billingRetur.map((item, key) => {
                return item.products.map((item2, key2) => {
                  return (
                    <tr
                      key={key2}
                      className={`${
                        key != item.products.length - 1
                          ? `border-bottom-dashed`
                          : ``
                      }`}
                    >
                      <td>
                        <div className="d-flex flex-column">
                          <span className="fw-bold">
                            {item2.product_alias} [{item2.product_size}]
                          </span>
                          <span>{item2.quantity}</span>
                          <span style={{ fontSize: "10px" }}>
                            {item2.product_code}
                          </span>
                        </div>
                      </td>
                      <td
                        className="text-end"
                        style={{ verticalAlign: "top" }}
                      ></td>
                    </tr>
                  );
                });
              })}
              {/* {pos.detailTransaction.billingRetur?.details?.map((item, key) => (
                <tr
                  key={key}
                  className={`${
                    key != pos.detailTransaction.billingRetur.length - 1
                      ? `border-bottom-dashed`
                      : ``
                  }`}
                >
                  <td>
                    <div className="d-flex flex-column">
                      <span className="fw-bold">
                        {item.product.product_alias} [{item.product_size}]
                      </span>
                      <span>{item.quantity}</span>
                      <span style={{ fontSize: "10px" }}>
                        {item.product_code}
                      </span>
                    </div>
                  </td>
                  <td
                    className="text-end"
                    style={{ verticalAlign: "top" }}
                  ></td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      ) : null}

      {pos.detailTransaction.billingRetur.length ? (
        <div className="mb-1">
          <div>Exchange Item(s)</div>
        </div>
      ) : null}
      <table className="w-100 table-fit table-item">
        <thead>
          <tr>
            <th style={{ width: "65%" }}>Items</th>
            <th className="text-center" style={{ width: "15%" }}>
              Qty
            </th>
            <th className="text-end">Price</th>
            {/* <th>Qty</th>
            <th className="text-end">Total</th> */}
          </tr>
        </thead>
        <tbody className="content-row">
          {pos.detailTransaction.data.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != pos.detailTransaction.data.length - 1
                  ? `border-bottom-dashed`
                  : ``
              }`}
            >
              <td>
                <div className="d-flex flex-column">
                  <span className="fw-bold medium-font">
                    {item.product_alias} [{item.product_size}]
                  </span>
                  {/* <span>{item.qty}</span> */}
                  <span className="small-font">{item.product_code}</span>
                </div>
              </td>
              <td
                className="text-center medium-font"
                style={{ verticalAlign: "top" }}
              >
                x{item.qty}
              </td>
              <td
                className="text-end medium-font"
                style={{ verticalAlign: "top" }}
              >
                <div className="d-flex flex-column">
                  <div className="fw-bold">
                    {receiptCurrency.format(item.grandtotal).replace(".", ",")}
                  </div>
                  {item.discount > 0 ? (
                    <del>
                      {receiptCurrency.format(item.subtotal).replace(".", ",")}
                    </del>
                  ) : null}
                </div>
              </td>
              {/* <td>{item.qty}</td>
              <td className="text-end">
                {rupiahCurrency.format(item.subtotal)}
              </td> */}
            </tr>
          ))}
          <tr>
            <td colSpan={4}>
              <table className="w-100 table-fit">
                <tbody>
                  <tr>
                    <td className="fw-bold medium-font">SUBTOTAL</td>
                    <td className="text-end fw-bold medium-font">
                      {receiptCurrency
                        .format(
                          pos.detailTransaction.billingSubtotal -
                            pos.detailTransaction.billingDiscount
                        )
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex flex-column ">
                        <div className="fw-bold medium-font">VAT(11%)</div>
                        <div>Included in product price</div>
                      </div>
                    </td>
                    <td className="fw-bold text-end medium-font">
                      {receiptCurrency
                        .format(
                          calculateVAT(
                            pos.detailTransaction.billingSubtotal -
                              pos.detailTransaction.billingDiscount
                          )
                        )
                        .replace(".", ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-secondary fw-bolder medium-font">
                      TOTAL
                    </td>
                    <td className="text-secondary fw-bolder text-end medium-font">
                      {receiptCurrency
                        .format(pos.detailTransaction.billingGrandTotal)
                        .replace(".", ",")}
                    </td>
                  </tr>
                  {/* {pos.detailTransaction.billingPaid > 0 && (
                    <tr
                      style={{
                        borderTop: "1px dashed #444",
                      }}
                    >
                      <td className="text-end">Cash :</td>
                      <td className="text-end">
                        {rupiahCurrency.format(
                          pos.detailTransaction.billingPaid
                        )}
                      </td>
                    </tr>
                  )}
                  {pos.detailTransaction.billingPaid > 0 && (
                    <tr>
                      <td className="text-end">Change :</td>
                      <td className="text-end">
                        {rupiahCurrency.format(
                          pos.detailTransaction.billingPaid -
                            pos.detailTransaction.billingGrandTotal
                        )}
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <table className="w-100 table-fit">
                <tbody>
                  {pos.detailTransaction?.billingPaidType?.map((item, key) => (
                    <tr key={key} className="medium-font">
                      <td>{item.payment_method}</td>
                      <td className="text-end fw-bold">
                        {receiptCurrency
                          .format(item.payment_amount)
                          .replace(".", ",")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
          {pos.detailTransaction?.billingPaidType?.some(
            (item) => item.payment_method === "CASH RUPIAH"
          ) ? (
            <tr>
              <td colSpan={4}>
                <table
                  className="w-100 table-fit"
                  style={{ borderTop: "none" }}
                >
                  <tbody>
                    <tr className="medium-font">
                      <td>Change Due</td>
                      <td className="text-end fw-bold">
                        {receiptCurrency
                          .format(
                            pos.detailTransaction.billingPaid -
                              pos.detailTransaction.billingGrandTotal
                          )
                          .replace(".", ",")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <Footer
        billingCode={pos.detailTransaction.billingCode}
        transDate={pos.detailTransaction.billingDate}
        cashierName={pos.detailTransaction.cashierName}
      />
    </div>
  );
};

export default PrintableComponent;
