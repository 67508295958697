// src/components/PrivateRoute.js

import { useAuth } from "src/hooks/useAuth";
import React from "react";
import { Navigate } from "react-router-dom";
import useUserValidation from "src/hooks/useValidation";

const PrivateRoute = ({ children }) => {
  // const { getToken } = useAuth();
  // const { token, user } = useUserValidation();
  // console.log("token ", token);
  // console.log("user ", user);
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
