import React from "react";
import "./style.css";
import ImageWithBasePath from "src/core/img/imagewithbasebath";

const ProductPOS = () => {
  return (
    <div className="pos-logo d-flex align-items-center justify-content-center">
      <div className="icon-head text-center">
        <ImageWithBasePath
          src="assets/img/daunlogo.png"
          width={200}
          height={150}
          alt="Receipt Logo"
        />
        <h5>POS</h5>
      </div>
    </div>
  );
};

export default ProductPOS;
