import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable";
import Header from "./Header";
import { getWarehouse } from "src/redux/store/warehouse";
import {
  deleteCashBasis,
  getCashBasis,
  getCashBasisDetail,
} from "src/redux/store/cashier";
import { rupiahCurrency } from "src/helper/currency";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DetailModal from "./DetailModal";

const CashBasis = () => {
  const cashier = useSelector((state) => state.cashier);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [search, setSearch] = useState("");
  const store = JSON.parse(localStorage.getItem("store"));

  useEffect(() => {
    dispatch(
      getCashBasis({
        limit,
        // offset: skip,
        page,
        stock_order_code: q,
        order: "stock_order_code",
      })
    );
  }, [limit, skip, q, dispatch, page]);

  useEffect(() => {
    dispatch(getWarehouse());
  }, [dispatch]);

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    setSkip(offset);
    setLimit(limit);
    setPage(pagination.current);
  };

  const handleDelete = (item) => {
    MySwal.fire({
      title: `Cancel this data? <br/><code>${item.number}</code>`,
      text: "",
      showCancelButton: true,
      icon: "warning",
      // cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(deleteCashBasis(item.id)).unwrap();
        // console.log("confirmed");
      } else {
        // console.log("canceled");
      }
    });
  };

  const handleDetail = (item) => {
    dispatch(getCashBasisDetail({ cashier_id: item.id }));
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Ref Number",
      dataIndex: "number",
      render: (_, item, index) => {
        return (
          <button
            className="btn text-info link-underline-info"
            onClick={() => handleDetail(item)}
          >
            <u>{item.number}</u>
          </button>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Book Type",
      dataIndex: "book_type",
    },
    {
      title: "Total Transaction",
      dataIndex: "total_transaction",
      render: (_, item) => {
        return rupiahCurrency.format(item.total);
      },
    },
    {
      title: "Action",
      render: (_, item, index) => {
        return (
          <div className="d-flex justify-content-start align-items-center gap-1 py-2">
            <button className="btn btn-sm" onClick={() => handleDelete(item)}>
              <span className="las la-times fs-4 text-danger"></span>
            </button>
          </div>
        );
      },
    },
  ];

  const MySwal = withReactContent(Swal);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Header />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            {/* <div className="table-top">
              <div className="search-set">
                <form onSubmit={handleSearch}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className="btn btn-searchset" type="submit">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="table-responsive">
              <Table
                tableClassName={"tight-table"}
                className="table datanew"
                columns={columns}
                dataSource={cashier.data}
                loading={cashier.loading}
                total={cashier.total}
                handleChange={handleChange}
                pageSize={limit}
              />
            </div>
          </div>
        </div>
      </div>

      <DetailModal />
    </div>
  );
};

export default CashBasis;
