import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { scanProduct } from "src/redux/store/pos";
import {
  scanProductOut,
  setModalNewItem,
  setModalProductSelect,
  setModalQtySelect,
} from "src/redux/store/transaction";

const SearchProduct = () => {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const store = JSON.parse(localStorage.getItem("store"));

  const searchProductCode = (e) => {
    e.preventDefault();
    dispatch(
      scanProductOut({
        txtProductCode: query,
        txtWarehouseId: store.store,
      })
    );
    // dispatch(setModalNewItem(true));
    // dispatch(setModalProductSelect(false));
    setQuery("");
  };
  return (
    <div className="search-set">
      <form onSubmit={searchProductCode}>
        <div className="search-input">
          <input
            type="text"
            placeholder="Scan or search by product code"
            className="form-control search-pos formsearch"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            className="btn btn-searchset btn-searchset-custom"
            type="submit"
          >
            <i
              data-feather="search"
              className="feather-search text-secondary"
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchProduct;
