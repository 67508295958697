import { Result } from "antd";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModalResult } from "src/redux/store/cashier";

export default function ResultModal() {
  const dispatch = useDispatch();
  const cashier = useSelector((state) => state.cashier);
  const navigate = useNavigate();
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={cashier.create.result}
      //   onHide={handleClose}
    >
      <Modal.Body>
        {cashier.create.error ? (
          <Result
            status="error"
            title="Oopps"
            subTitle={cashier.create.error}
            extra={[
              <Button
                type="primary"
                key="finish"
                onClick={() => dispatch(setModalResult(false))}
              >
                Close
              </Button>,
            ]}
          />
        ) : (
          <Result
            status="success"
            title="Create Data Success"
            //   subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
              <Button
                type="primary"
                key="finish"
                onClick={() => navigate("/cashier/cash-basis")}
              >
                Close
              </Button>,
            ]}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
