import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../modal.css";
import { Printer } from "react-feather";
import {
  setModalTransaction,
  setReturType,
  setReturReason,
  setReturReasonNote,
  setModalExchangeComplete,
  resetDataRetur,
  resetReturCart,
} from "src/redux/store/transaction";
const ExchangeComplete = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleFinish = () => {
    dispatch(setModalTransaction(true));
    dispatch(setModalExchangeComplete(false));
    dispatch(setReturReason(null));
    dispatch(setReturReasonNote(""));
    dispatch(resetDataRetur());
    dispatch(resetReturCart());
    dispatch(setReturType(""));
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalExchangeComplete}
    >
      <Modal.Body>
        <div className="px-3 mt-5 exchange-summary">
          <h2 className="mb-2">
            <span className="text-capitalize">{transaction.returType}</span>{" "}
            Complete
          </h2>

          <p>Successfully {transaction.returType} product</p>

          {/* {transaction.returType == "exchange" && (
            <div className="mt-4">
              <h5 className="mb-4 text-muted">Receipt options</h5>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary flex-fill fw-bold d-flex gap-2 align-items-center"
              >
                <Printer />
                <span>Print receipt</span>
              </button>
            </div>
          )} */}
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary flex-fill fw-bold fs-5"
            onClick={handleFinish}
          >
            <span>Done</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExchangeComplete;
