import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "src/modules/pos/modal/retur/BackButton";

const mainTitle = "Stock Opname";
const subtitle = "Manage your stock adjustment";

const Header = () => {
  const stock = useSelector((state) => state.stockOpname);
  const navigate = useNavigate();
  return (
    <div className="page-header">
      <div className="add-item d-flex align-items-center gap-4">
        <BackButton
          relative
          handleClick={() => navigate("/cashier/cash-basis")}
        />
        <div className="page-title">
          <h4>Create Cash Basis</h4>
        </div>
      </div>
    </div>
  );
};

export default Header;
