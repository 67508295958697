import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "../modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  Minus,
  Plus,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  addProduct,
  setModalQtySelect,
  setModalNewItem,
  addCart,
  incrementQuantity,
  decrementQuantity,
  setModalExchangeSummary,
  resetReturCart,
} from "src/redux/store/transaction";
import { receiptCurrency } from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./BackButton";
import Select from "react-select";
import SearchProduct from "./SearchProduct";

const ReturTableRow = ({ item }) => {
  // const [check, setChecked] = useState(item.checked);
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleAddProduct = (item) => {
    if (item.system > 0) {
      const payload = {
        code: item.code,
        size: item.size,
        name: item.name,
        alias: item.alias,
        discount: item.discount,
        price: item.price,
        qty: 1,
      };
      dispatch(addCart(payload));
      dispatch(setModalNewItem(true));
      dispatch(setModalProductSelect(false));
    }
  };

  return (
    <tr onClick={() => handleAddProduct(item)}>
      <td>
        <div>
          <div className="fw-bold user-select-none">{item?.code}</div>
          <div className="text-muted user-select-none">
            <small>{item?.name}</small>
          </div>
          <div className="text-muted user-select-none">
            <small>{item?.alias}</small>
          </div>
        </div>
      </td>
      <td>{item?.size}</td>
      <td>
        {item?.system > 0 ? (
          item?.system
        ) : (
          <span className="text-danger">Sold out</span>
        )}
      </td>
    </tr>
  );
};

const Item = ({ item }) => {
  const dispatch = useDispatch();
  const handleIncrement = () => {
    if (item.qty + 1 <= item.stock) {
      dispatch(
        incrementQuantity({
          product_code: item.product_code,
          product_discount: item.product_discount,
          product_price: item.product_price,
        })
      );
    }
  };
  const handleDecrement = () => {
    dispatch(
      decrementQuantity({
        product_code: item.product_code,
        product_discount: item.product_discount,
        product_price: item.product_price,
      })
    );
  };
  return (
    <div className="d-flex justify-content-between align-items-center gap-4">
      <div>
        <div className="fw-bold user-select-none">{item.product_code}</div>
        <div className="text-muted user-select-none">
          <small>{item.product_name}</small>
        </div>
        <div className="text-muted user-select-none">
          <small>
            {item.product_alias} | ({" "}
            <span className="fw-bold">{item.product_size}</span> )
          </small>
        </div>
        {/* <div className="text-muted user-select-none">
            <span className="fw-bold">{item.size}</span>{" "}
        </div>
        <div className="d-flex align-items-start gap-2">
            <div className="d-flex flex-column">
            <div className="fw-bolder">
                {rupiahCurrency.format(
                calculateDiscount(item.price, item.discount).afterDisc
                )}
            </div>
            {item.discount > 0 && <del>{rupiahCurrency.format(item.price)}</del>}
            </div>
            {item.discount > 0 ? (
            <div className="badge bg-secondary" style={{ fontSize: "10px" }}>
                {item.discount} % Off
            </div>
            ) : null}
        </div> */}
      </div>

      <div className="d-flex gap-2 align-items-center user-select-none">
        <div
          className="retur-cart-button bg-outline-secondary"
          onClick={handleDecrement}
        >
          <Minus width={20} />
        </div>
        <div className="retur-cart-qty">{item.qty}</div>
        <div
          className="retur-cart-button bg-outline-secondary"
          onClick={handleIncrement}
        >
          <Plus width={20} />
        </div>
      </div>
    </div>
  );
};

const ProductSelect = () => {
  const transaction = useSelector((state) => state.transaction);
  const stock = useSelector((state) => state.stockOpname);
  const dispatch = useDispatch();

  const handleBackReturReason = () => {
    dispatch(resetReturCart());
    dispatch(setModalProductSelect(false));
    dispatch(setModalExchangeReason(true));
  };

  // const handleListNewItem = () => {
  //   dispatch(setModalProductSelect(false));
  //   dispatch(setModalNewItem(true));
  // };

  const handleExchangeSummary = () => {
    dispatch(setModalExchangeSummary(true));
    dispatch(setModalProductSelect(false));
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalProductSelect}
      //   onHide={handleBackReturReason}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <BackButton handleClick={handleBackReturReason} />
      <Modal.Body>
        <div className="mt-5">
          <h4>Select new items</h4>
        </div>

        <div className="stock-opname-input">
          <SearchProduct />
        </div>

        {transaction.returCart.length > 0 ? (
          <div className="px-3 mt-5">
            <h4 className="mb-2">New Items</h4>
            <div className="d-flex flex-column gap-3 retur-cart">
              {transaction.returCart.map((item, key) => (
                <Item key={key} item={item} />
              ))}
            </div>
          </div>
        ) : (
          <div className="px-3 mt-5">
            <p className="mb-2">No items added</p>
          </div>
        )}
        {/* <div className="px-3 mt-5">
          <h4 className="mb-2">New Items</h4>
          <div className="d-flex flex-column gap-3 retur-cart">
            {transaction.returCart.map((item, key) => (
              <Item key={key} item={item} />
            ))}
          </div>
        </div> */}

        {/* <div className="p-3 mt-3  product-select-list">
          <table className="w-100 retur-table">
            <thead className="border-bottom">
              <tr>
                <th>SKU</th>
                <th>Size</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {stock.productList.map((item, key) => (
                <ReturTableRow key={key} item={item} />
              ))}
            </tbody>
          </table>
        </div> */}
        <div className="modal-footer d-sm-flex justify-content-center">
          {transaction.returCart.length > 0 && (
            <button
              type="button"
              className="btn btn-secondary flex-fill"
              onClick={handleExchangeSummary}
            >
              Next
            </button>
          )}
        </div>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default ProductSelect;
