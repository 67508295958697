import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  setCashSplitBill,
  setMarkSplitBill,
  setPaymentMethodSplitBill,
  setRevertSplitBillRemaining,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "./modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  Minus,
  MinusCircle,
  Plus,
  Printer,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  setModalQtySelect,
  resetReturProduct,
  setModalNewItem,
  incrementQuantity,
  decrementQuantity,
  setExchangeNote,
  setModalExchangeComplete,
  resetDataRetur,
  resetReturCart,
} from "src/redux/store/transaction";
import {
  setModalSplitBill,
  setModalSplitBillMarkPayment,
} from "src/redux/store/pos";
import {
  calculateDiscount,
  receiptCurrency,
  rupiahCurrency,
} from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./retur/BackButton";
import Select from "react-select";
import SearchProduct from "./retur/SearchProduct";
import { PAYMENT_METHOD } from "src/config/constants";

const SplitBillMarkPayment = () => {
  const pos = useSelector((state) => state.pos);
  const [amountDisplay, setAmountDisplay] = useState(0);
  const dispatch = useDispatch();

  const handleFinish = () => {
    dispatch(setMarkSplitBill());
  };

  const handleBack = () => {
    dispatch(setModalSplitBill(true));
    dispatch(setModalSplitBillMarkPayment(false));
    dispatch(setRevertSplitBillRemaining());
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalSplitBillMarkPayment}
    >
      <Modal.Body>
        <BackButton handleClick={handleBack} />
        <div className="px-3 mt-5 exchange-summary">
          <h2 className="mb-2">
            <span className="text-capitalize"></span> Payment{" "}
            {pos.splitBill.data.length} :{" "}
            {receiptCurrency.format(pos.splitBill.cash)}
          </h2>

          <p>
            Payment method :{" "}
            {pos.splitBill.data[pos.splitBill.data.length - 1]?.paymentMethod}
          </p>
          {/* <div className="d-flex flex-column position-relative">
            <label className="form-label">
              Payment {pos.splitBill.data.length + 1}
            </label>
            <input
              className="form-control"
              placeholder="Amount"
              value={amountDisplay}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {pos.splitBill.data.length ? (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining : {rupiahCurrency.format(pos.splitBill.remaining)}
              </span>
            ) : (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining :{" "}
                {rupiahCurrency.format(
                  pos.totalAmount - pos.totalDiscount - pos.splitBill.cash
                )}
              </span>
            )}
          </div> */}
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary flex-fill fw-bold fs-5"
            onClick={handleFinish}
          >
            <span>Mark as paid</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SplitBillMarkPayment;
