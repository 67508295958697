import { Divider, Result, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Calendar } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { rupiahCurrency } from "src/helper/currency";
import {
  addNewColumn,
  deleteColumn,
  setModalDetail,
  setModalResult,
  updateCashBasis,
  updateColumn,
  updateData,
} from "src/redux/store/cashier";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const bookTypes = [
  {
    value: 1,
    label: "Pengeluaran",
  },
  {
    value: 2,
    label: "Penerimaan",
  },
];

export default function DetailModal() {
  const dispatch = useDispatch();
  const cashier = useSelector((state) => state.cashier);
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClickNew = () => {
    dispatch(addNewColumn({ edit: true }));
  };

  const handleClose = () => {
    dispatch(setModalDetail(false));
  };

  const handleClickDelete = (index) => {
    dispatch(deleteColumn({ index, edit: true }));
  };
  const handleChange = (key, value, index) => {
    dispatch(updateColumn({ key, value, index, edit: true }));
  };

  const handleChangeAmount = (value, index) => {
    let inputValue = value;
    if (inputValue == "") {
      inputValue = "0";
    }
    inputValue = inputValue.replace(/[^0-9,]/g, "");
    dispatch(
      updateColumn({
        key: "amount",
        value: parseInt(inputValue),
        index,
        edit: true,
      })
    );
  };

  useEffect(() => {
    const checkData = cashier.detail.data?.items.every(
      (item) => item.reference !== "" && item.amount > 0
    );
    if (checkData) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [cashier]);

  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            placeholder="Enter reference"
            defaultValue={item.reference}
            disabled={!edit}
            onChange={(e) => handleChange("reference", e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            disabled={!edit}
            value={rupiahCurrency.format(item.amount).replace(",", ".")}
            onChange={(e) => handleChangeAmount(e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            placeholder="Enter note"
            disabled={!edit}
            defaultValue={item.note}
            onChange={(e) => handleChange("note", e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "note",
      render: (_, item, index) => {
        return index == 0 ? (
          edit ? (
            <div className="">
              <button className="btn btn-info" onClick={handleClickNew}>
                <span className="las la-plus fs-5"></span>
              </button>
            </div>
          ) : null
        ) : edit ? (
          <div className="">
            <button
              className="btn btn-outline-danger"
              onClick={() => handleClickDelete(index)}
            >
              <span className="las la-trash fs-5"></span>
            </button>
          </div>
        ) : null;
      },
    },
  ];

  const handleSubmit = () => {
    const payload = {
      cashier_id: cashier.detail.data.id,
      txtTransaksiDate: moment(cashier.create.date).format("YYYY-MM-DD"),
      txtBuku: cashier.detail.data.book_type.value
        ? cashier.detail.data.book_type.value
        : bookTypes.find((item) => item.label === cashier.detail.data.book_type)
            .value,
      totalSum: cashier.detail.data.items.reduce(
        (acc, item) => acc + parseInt(item.amount),
        0
      ),
      items: cashier.detail.data.items.map((item) => {
        return {
          ...item,
          amount: parseInt(item.amount),
        };
      }),
    };
    dispatch(updateCashBasis(payload));
  };

  useEffect(() => {
    if (cashier.detail.modal) {
      setEdit(false);
    }
  }, [cashier.detail.modal]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={cashier.detail.modal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Detail Cashier <code>{cashier.detail.data?.number}</code>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-body">
            {cashier.detail.error && (
              <div className="alert alert-danger">{cashier.detail.error}</div>
            )}
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-blocks">
                  <label className="form-label">
                    Transaction Date <small className="text-danger">*</small>
                  </label>
                  <div className="input-blocks">
                    <Calendar className="info-img" />
                    <div className="input-groupicon d-grid">
                      <ReactDatePicker
                        selected={cashier.detail.data?.date}
                        onChange={(date) =>
                          dispatch(
                            updateData({ key: "date", value: date, edit: true })
                          )
                        }
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Choose Date"
                        className="datetimepicker"
                        disabled={!edit}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-blocks">
                  <label className="form-label">
                    Book Type <small className="text-danger">*</small>
                  </label>
                  <ReactSelect
                    className="mt-2"
                    classNamePrefix="react-select"
                    options={bookTypes}
                    placeholder="Book type"
                    value={bookTypes.find(
                      (item) => item.label === cashier.detail.data?.book_type
                    )}
                    onChange={(e) =>
                      dispatch(
                        updateData({ key: "book_type", value: e, edit: true })
                      )
                    }
                    isDisabled={!edit}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 text-start">
                <button className="btn" onClick={() => setEdit(!edit)}>
                  {edit ? (
                    <span className="las la-times text-danger fs-1"></span>
                  ) : (
                    <span className="las la-edit text-info fs-1"></span>
                  )}
                </button>
              </div>
            </div>

            <Divider variant="dashed" style={{ borderColor: "#7cb305" }} dashed>
              Transaction Detail
            </Divider>

            <div className="row pb-5">
              <div className="col-12">
                <div className="table-responsive">
                  <Table
                    tableClassName={"tight-table"}
                    className="table datanew"
                    columns={columns}
                    dataSource={cashier.detail.data?.items}
                    // loading={transferStockOut.loading}
                    // total={transferStockOut.total}
                    // handleChange={handleChange}
                    // pageSize={limit}
                    // rowKey={record => record.reference}
                    pagination={false}
                    scroll={{
                      y: 55 * 5,
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="d-grid">
              <div className="input-blocks">
                <label className="form-label">Transaction Note</label>
                <textarea
                  className="form-control"
                  value={cashier.create.transaction_note}
                  onChange={(e) =>
                    dispatch(
                      updateData({
                        key: "transaction_note",
                        value: e.target.value,
                      })
                    )
                  }
                ></textarea>
              </div>
            </div> */}
            <div className="d-flex justify-content-end mt-3">
              <div className="input-blocks">
                <label className="form-label">Total Transaction</label>
                <div className="fs-5 text-end">
                  {rupiahCurrency.format(
                    cashier.detail.data?.items.reduce(
                      (acc, item) => acc + parseInt(item.amount),
                      0
                    )
                  )}
                </div>
              </div>
            </div>

            <Divider></Divider>
            {edit ? (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary d-flex gap-2 align-items-center"
                  disabled={cashier.detail.loading || !valid}
                  onClick={handleSubmit}
                >
                  <span className="las la-save fs-5"></span>
                  {cashier.detail.loading ? `Processing` : `Submit`}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
