// hooks/useBeepSound.js
import { useRef } from "react";

const useBeepSound = () => {
  const audio = new Audio("/assets/beep.mp3");

  //   const audioRef = useRef(new Audio("/assets/beep.mp3"));

  const playOnce = () => {
    audio.currentTime = 0;
    audio.play();
  };

  const playTwice = () => {
    playOnce();
    setTimeout(() => playOnce(), 100);
  };

  return { playOnce, playTwice };
};

export default useBeepSound;
