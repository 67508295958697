import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  setCashSplitBill,
  setMarkSplitBill,
  setPaymentMethodSplitBill,
  toggleModalCompletedPayment,
  cancelAllSplitBill,
  submitTransaction,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "./modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  Minus,
  MinusCircle,
  Plus,
  Printer,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  setModalQtySelect,
  resetReturProduct,
  setModalNewItem,
  incrementQuantity,
  decrementQuantity,
  setExchangeNote,
  setModalExchangeComplete,
  resetDataRetur,
  resetReturCart,
} from "src/redux/store/transaction";
import {
  calculateDiscount,
  receiptCurrency,
  rupiahCurrency,
} from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./retur/BackButton";
import Select from "react-select";
import SearchProduct from "./retur/SearchProduct";
import { PAYMENT_METHOD } from "src/config/constants";
import moment from "moment";

const SplitBillFinish = () => {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();
  const staff = JSON.parse(localStorage.getItem("staff"));

  const handleFinish = async () => {
    const data = {
      txtSalesDate: moment().format("YYYY-MM-DD"),
      txtCustomer: "0",
      txtPaid: pos.totalAmount - pos.totalDiscount,
      txtSalesNote: pos.note,
      itemProduct: pos.items,
      txtPaidRef: pos.refCode,
      txtPaidNumber: pos.cardNumber,
      txtPaidType: pos.splitBill.data,
      user_id: staff?.id,
    };
    try {
      const res = await dispatch(submitTransaction(data)).unwrap();
    } catch (error) {
      console.log(error);
    }
    // dispatch(setMarkSplitBill());
  };

  const handleCancel = () => {
    dispatch(cancelAllSplitBill());
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.splitBill.isFinish}
    >
      <Modal.Body>
        {/* <BackButton handleClick={handleCancel} /> */}
        <div className="px-3 mt-5 exchange-summary">
          <h2 className="mb-2">Mark as paid</h2>

          <div className="mt-4">
            {pos.errorTransaction && (
              <div className="alert alert-outline-danger alert-dismissible fade show">
                {JSON.stringify(pos.errorTransaction)}
              </div>
            )}
            <h5 className="mb-4 text-muted">Payment summary</h5>
            {pos.splitBill.data.map((item, key) => (
              <div key={key} className="mb-3 d-flex justify-content-between">
                <div>
                  <div>
                    {" "}
                    <h5>Payment {key + 1}</h5>
                  </div>
                  <div>{item.paymentMethod}</div>
                </div>
                <h5>{receiptCurrency.format(item.total)}</h5>
              </div>
            ))}
          </div>

          <div className="mt-4">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div className="d-flex gap-3 align-items-center">
                <div>Total Split Bill</div>
              </div>

              <div>
                {receiptCurrency.format(
                  pos.splitBill.data.reduce((acc, item) => acc + item.total, 0)
                )}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div className="d-flex gap-3 align-items-center">
                <h5>Subtotal</h5>
              </div>

              <h5>
                {receiptCurrency.format(pos.totalAmount - pos.totalDiscount)}
              </h5>
            </div>
          </div>

          {pos.splitBill.change < 0 && (
            <div className="d-flex align-items-center justify-content-between my-3">
              <div className="d-flex gap-3 align-items-center">
                <div>Change</div>
              </div>

              <div>
                {receiptCurrency.format(Math.abs(pos.splitBill.change))}
              </div>
            </div>
          )}

          {/* <div className="mt-4">
            <h5 className="mb-4 text-muted">Receipt options</h5>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary flex-fill fw-bold d-flex gap-2 align-items-center"
            >
              <Printer />
              <span>Print receipt</span>
            </button>
          </div> */}
          {/* <p>
            Payment method :{" "}
            {pos.splitBill.data[pos.splitBill.data.length - 1]?.paymentMethod}
          </p> */}
          {/* <div className="d-flex flex-column position-relative">
            <label className="form-label">
              Payment {pos.splitBill.data.length + 1}
            </label>
            <input
              className="form-control"
              placeholder="Amount"
              value={amountDisplay}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {pos.splitBill.data.length ? (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining : {rupiahCurrency.format(pos.splitBill.remaining)}
              </span>
            ) : (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining :{" "}
                {rupiahCurrency.format(
                  pos.totalAmount - pos.totalDiscount - pos.splitBill.cash
                )}
              </span>
            )}
          </div> */}
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg btn-outline-primary flex-fill fw-bold fs-5"
            onClick={handleCancel}
          >
            {pos.loadingTransaction ? <span>-</span> : <span>Cancel</span>}
          </button>
          <button
            type="button"
            className="btn btn-lg btn-secondary flex-fill fw-bold fs-5"
            disabled={pos.loadingTransaction}
            onClick={handleFinish}
          >
            {pos.loadingTransaction ? (
              <span>Processing</span>
            ) : (
              <span>Confirm</span>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SplitBillFinish;
