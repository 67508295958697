/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table } from "antd";

const Datatable = ({ props, columns, dataSource, loading, total, handleChange, pageSize = 10, tableClassName }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Table
      key={props}
      className={`table datanew dataTable no-footer ${tableClassName}`}
      // rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      loading={loading}
      // pagination={{
      //   total,
      //   pageSize
      // }}
      scroll={{ x: "max-content", y: "max-content" }}
      pagination={total ? {
        total, pageSize// total count returned from backend
     } : false}
     onChange={handleChange}
    />
  );
};

export default Datatable;
