import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setModalCheckIn, storeAbsen } from "src/redux/store/user";
import "../style.css";

const salesList = [
  {
    label: "John Doe",
    value: "John Doe",
  },
  {
    label: "John Wick",
    value: "John Wick",
  },
];

export default function UserSelect({ setChecked }) {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState({
    label: "",
    value: "",
  });
  const user = useSelector((store) => store.user);
  const store = JSON.parse(localStorage.getItem("store"));
  const dispatch = useDispatch();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const txtUserId = selected.value;
  //   const txtDate = moment().format("YYYY-MM-DD");
  //   const txtCheckIn = moment().format("HH:mm:ss");
  //   const payload = {
  //     txtUserId,
  //     txtDate,
  //     txtCheckIn,
  //     txtWarehouseId: store.store,
  //   };
  //   dispatch(storeAbsen(payload));
  //   setSelected({
  //     label: "",
  //     value: "",
  //   });
  // };

  const scan = (e) => {
    e.preventDefault();
    const txtUserId = query;
    const txtDate = moment().format("YYYY-MM-DD");
    const txtCheckIn = moment().format("HH:mm:ss");
    const payload = {
      txtUserId,
      txtDate,
      txtCheckIn,
      txtWarehouseId: store.store,
    };
    dispatch(storeAbsen(payload));
    setQuery("");
    // dispatch(setModalCheckIn(true));
  };

  return (
    <form onSubmit={scan}>
      <div className="d-flex align-items-center justify-content-between gap-4">
        <div className="absen-scan-input">
          <input
            type="text"
            className="form-control"
            placeholder="Scan QR code or enter staff ID number"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button className="btn" type="submit">
            <i className="las la-qrcode fs-2 text-secondary" />
          </button>
        </div>
      </div>
    </form>
  );
}
