import React from "react";
const mainTitle = "Stock Movement";
const subtitle = "Movement stock every warehouse";

const Header = () => {
   return (
      <div className="page-header">
         <div className="add-item d-flex">
            <div className="page-title">
               <h4>{mainTitle}</h4>
               <h6>{subtitle}</h6>
            </div>
         </div>
      </div>
   );
};

export default Header;
