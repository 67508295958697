import pos from "./store/pos";
import transferStockIn from "./store/transferStockIn";
import transferStockOut from "./store/transferStockOut";
import user from "./store/user";
import role from "./store/role";
import product from "./store/product";
import transaction from "./store/transaction";
import warehouse from "./store/warehouse";
import stockOpname from "./store/stockopname";
import productSearch from "./store/productSearch";
import stockMovement from "./store/stockMovement";
import cashier from "./store/cashier";

const rootReducer = {
  pos,
  transferStockIn,
  transferStockOut,
  user,
  role,
  product,
  transaction,
  warehouse,
  stockOpname,
  productSearch,
  stockMovement,
  cashier,
};

export default rootReducer;
