import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalCheckIn,
  setModalCheckOut,
  setModalScanCheckOut,
  storeAbsen,
} from "src/redux/store/user";

const ModalScanCheckout = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const store = JSON.parse(localStorage.getItem("store"));

  const handleClose = () => {
    dispatch(setModalScanCheckOut(false));
  };

  const scan = (e) => {
    e.preventDefault();
    const txtUserId = query;
    const txtDate = moment().format("YYYY-MM-DD");
    const txtCheckOut = moment().format("HH:mm:ss");
    const payload = {
      txtUserId,
      txtDate,
      txtCheckOut,
      txtWarehouseId: store.store,
      type: "out",
    };
    dispatch(storeAbsen(payload));
    setQuery("");
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={user.modalScanCheckOut}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Check Out</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={scan}>
          <div className="d-flex align-items-center justify-content-between gap-4">
            <div className="absen-scan-input">
              <input
                type="text"
                className="form-control"
                placeholder="Scan QR code or enter staff ID number"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button className="btn" type="submit">
                <i className="las la-qrcode fs-2 text-secondary" />
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalScanCheckout;
