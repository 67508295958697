import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalAdd } from "src/redux/store/stockopname";
import SelectAsync from "react-select/async";
import Select from "react-select";
import Table from "src/core/pagination/datatable";
import { Calendar, PlusCircle, Trash2 } from "react-feather";
import { Divider } from "antd";
import "./style.css";
import axios from "src/config/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createStockOpname,
  setDate,
  setWarehouseID,
} from "src/redux/store/stockopname";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import moment from "moment";
import { setActiveStaff } from "src/redux/store/pos";
import { Link } from "react-router-dom";

const AddModal = () => {
  let navigate = useNavigate();

  const transferStockOut = useSelector((state) => state.transferStockOut);
  const stock = useSelector((state) => state.stockOpname);
  const warehouse = useSelector((state) => state.warehouse);
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };
  const [created, setCreated] = useState({
    label: "",
    value: "",
  });
  const user = JSON.parse(localStorage.getItem("userData"));
  const store = JSON.parse(localStorage.getItem("store"));
  const users = useSelector((store) => store.user);
  const pos = useSelector((store) => store.pos);

  const handleCreate = async (e) => {
    e.preventDefault();
    // async function here
    try {
      const payload = {
        warehouse_id: store.store,
        date: moment(stock.create.date).format("YYYY-MM-DD hh:mm:ss"),
        created_by: created.value,
      };
      const res = await dispatch(createStockOpname(payload)).unwrap();
      navigate(`/stock-opname/create/${res.data.id_so}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (stock.create.warehouseID.value && stock.create.date) {
    if (created.value) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [created]);

  const handleSelect = (id, name) => {
    dispatch(setActiveStaff({ id, name }));
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={stock.modalAdd}
      onHide={() => dispatch(toggleModalAdd())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Create Stock Opname</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleCreate}>
          <div className="row">
            <div className="col-lg-4">
              <div className="input-blocks">
                <label>Date</label>
                <div className="input-blocks">
                  <Calendar className="info-img" />
                  <div className="input-groupicon">
                    <DatePicker
                      selected={moment(stock.create.date).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      placeholderText="Choose Date"
                      className="datetimepicker d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input-blocks">
                <label>Sales</label>

                {/* <ul className="nav user-menu">
                  <li className="nav-item dropdown has-arrow main-drop select-store-dropdown staff-list"> */}
                {/* {!pos.staff.id && <div className="staff-list-hint"></div>} */}
                {/* <Link
                  to="#"
                  className="nav-link select-store react-select border p-2"
                  data-bs-toggle="dropdown"
                >
                  <span className="user-detail">
                    <span className="user-name">
                      {pos.staff?.id == null
                        ? `Select sales name`
                        : pos.staff?.name}
                    </span>
                  </span>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                  {users.listAbsen
                    .filter((item) => item.check_out === null)
                    .map((item, key) => (
                      <div
                        role="button"
                        key={key}
                        onClick={() =>
                          handleSelect(item.user_id, item?.user?.name)
                        }
                        className="dropdown-item"
                      >
                        {item?.user?.name}
                      </div>
                    ))}
                </div> */}

                <Select
                  classNamePrefix="react-select"
                  options={users.listAbsen
                    .filter((item) => item.check_out === null)
                    .map((item, key) => {
                      return {
                        value: item.user_id,
                        label: item?.user?.name,
                      };
                    })}
                  value={created}
                  onChange={(e) => setCreated(e)}
                />
                {/* </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input-block">
                <label className="mb-2">Warehouse</label>
                <input
                  type="text"
                  className="form-control"
                  value={store.store_name}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={() => dispatch(toggleModalAdd())}
            >
              Cancel
            </button>
            <button
              disabled={!valid || stock.loadingCreateSO}
              type="submit"
              className="btn btn-submit"
            >
              Create
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
