import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "src/modules/pos/modal/retur/BackButton";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="page-wrapper">
      <div className="content position-relative">
        <div className="card table-list-card p-3">
          <div
            className="d-flex align-items-center gap-4 position-relative"
            //   style={{ marginTop: "20px" }}
          >
            <BackButton
              relative
              handleClick={() => navigate("/stock-opname")}
            />
            <div>
              <h5>Data not exist</h5>
              <p className="text-muted">Back to menu</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
