import React from "react";

const Footer = ({ transDate, billingCode, cashierName }) => {
  const npwp = "76.023.776.00000";
  const note = [
    `All prices are inclusive of VAT`,
    `Exhange Policy: You may exchange or request hemming within 3 days of
    the purchase date. Items must be unworn, unwashed, unaltered, with
    hangtag, accompanied with a receipt`,
    `FINAL SALE items cannot be refunded or exchanged`,
    `*Enjoy Bali*`,
  ];
  return (
    <div className="text-center footer small-font mt-3">
      <div>{transDate}</div>
      <div>Staff at register : {cashierName}</div>
      <div>Receipt : {billingCode}</div>
      <div>NPWP : {npwp}</div>
      <div>{note[0]}</div>
      <p className="pt-3">{note[1]}</p>
      <p>{note[2]}</p>
      <p>{note[3]}</p>
    </div>
  );
};

export default Footer;
