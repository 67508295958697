import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "../modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  resetRetur,
} from "src/redux/store/transaction";
import { receiptCurrency } from "src/helper/currency";

const ReturOptions = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleConfirmRetur = (e) => {
    e.preventDefault();
    dispatch(closeModalTransaction());
    dispatch(toggleModalCompletedRetur());
  };

  const handleBackToTransaction = () => {
    dispatch(setModalTransaction(true));
    dispatch(setModalReturOptions(false));
    dispatch(resetDetailTransaction());
  };

  const handleChooseType = (val) => {
    dispatch(resetRetur());
    dispatch(setReturType(val));
    dispatch(setModalReturOptions(false));
    dispatch(setModalRetur(true));
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalOptions}
    >
      <Modal.Body className="text-center">
        {transaction.loadingDetailTransaction ? (
          <p>Loading data..</p>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center mb-3">
              <div className="d-flex gap-2 align-items-center">
                <span>Transaction ID :</span>
                <h5>{transaction?.detailTransaction?.billingCode}</h5>{" "}
                {transaction?.detailTransaction?.isGift ? (
                  <div className="badge badge-primary">Buy as Gift</div>
                ) : null}
              </div>
              <div className="d-flex gap-2 align-items-center">
                <span>Total :</span>
                <h5>
                  {receiptCurrency
                    .format(transaction?.detailTransaction?.billingGrandTotal)
                    .replace(".", ",")}
                </h5>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <span>Date :</span>
                <span>{transaction?.detailTransaction?.billingDate}</span>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <span>
                  {transaction?.detailTransaction?.data?.reduce(
                    (accumulator, item) => accumulator + item.qty,
                    0
                  )}{" "}
                  (items)
                </span>
              </div>
            </div>

            <div className="icon-head">
              <Link to="#">
                <HelpCircle className="feather-40" />
              </Link>
            </div>

            <h4>Choose Options</h4>

            <div className="d-flex gap-3 justify-content-center align-items-center mt-4 mb-3">
              {/* <div
            className="retur-options-item"
            role="button"
            onClick={() => handleChooseType("return")}
          >
            <CornerLeftDown />
            <div className="mt-3">Retur</div>
          </div> */}
              <div
                className="retur-options-item"
                role="button"
                onClick={() => handleChooseType("exchange")}
              >
                <RefreshCcw />
                <div className="mt-3">Exchange</div>
              </div>
            </div>

            <form onSubmit={handleConfirmRetur}>
              <div className="modal-footer d-sm-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-warning me-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#print-receipt"
                  onClick={handleBackToTransaction}
                >
                  Back to transaction
                </button>
              </div>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReturOptions;
