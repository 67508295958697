import React from "react";

import * as Icon from "react-feather";

export const SidebarData = [
  {
    label: "Main",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "Dashboard",
        link: "/dashboard",
        icon: <Icon.Grid />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "POS",
        link: "/pos",
        icon: <Icon.HardDrive />,
        showSubRoute: false,
        submenu: false,
      },
      // {
      //   label: "Print Barcode",
      //   link: "/pos",
      //   icon: <Icon.AlignJustify />,
      //   showSubRoute: false,
      //   submenu: false,
      // },
    ],
  },
  {
    label: "Stock",
    submenuOpen: true,
    submenuHdr: "Stock",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      // {
      //   label: "Manage Stock",
      //   link: "/manage-stocks",
      //   icon: <Icon.Package />,
      //   showSubRoute: false,
      //   submenu: false,
      // },
      {
        label: "Outgoing Goods",
        link: "/outgoing-goods",
        icon: <Icon.Clipboard />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Incoming Goods",
        link: "/incoming-goods",
        icon: <Icon.Truck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Stock Opname",
        link: "/stock-opname",
        icon: <Icon.Server />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Search Product",
        link: "/search-product",
        icon: <Icon.Search />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Stock Movement",
        link: "/stock-movement",
        icon: <Icon.Package />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
  {
    label: "Cashier",
    submenuOpen: true,
    submenuHdr: "Cashier",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      {
        label: "Cashier",
        link: "/cashier/cash-basis",
        icon: <Icon.CreditCard />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
];
