// src/features/cashierSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,

  create: {
    date: new Date(),
    book_type: {
      value: null,
      label: null,
    },
    payment_type: {
      value: null,
      label: null,
    },
    note: "",
    transaction_note: "",
    data: [
      {
        reference: "",
        amount: 0,
        note: "",
      },
    ],
    result: false,
    error: "",
  },

  detail: {
    data: null,
    modal: false,
    loading: false,
    error: "",
  },
};

export const getCashBasis = createAsyncThunk(
  "cashier/cash-basis",
  async (params, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `/v1/cashier`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            warehouse_id: store.store,
            ...params,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data;
      return {
        data: result.data,
        total: result.meta.total,
        params,
      };
      // return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCashBasisDetail = createAsyncThunk(
  "cashier/cash-basis/detail",
  async (params, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `/v1/cashier-show`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            cashier_id: params.cashier_id,
            ...params,
          },
        }
        // `https://dummyjson.com/products?limit=${params.limit}&skip=${params.skip}&select=title,price,sku,weight,brand`
      );
      const result = response.data;
      return {
        data: result.data,
        params,
      };
      // return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCashBasis = createAsyncThunk(
  "cashier/cash-basis/create",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `/v1/cashier`,
        { ...data, warehouse_id: store.store },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      dispatch(getCashBasis(getState().cashier.params));
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCashBasis = createAsyncThunk(
  "cashier/cash-basis/update",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `/v1/cashier-update`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      dispatch(getCashBasis(getState().cashier.params));
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCashBasis = createAsyncThunk(
  "cashier/cash-basis/delete",
  async (cashier_id, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `/v1/cashier-delete`,
        { cashier_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      dispatch(getCashBasis(getState().cashier.params));
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cashierSlice = createSlice({
  name: "cashier",
  initialState,
  reducers: {
    addNewColumn: (state, { payload }) => {
      if (payload.edit) {
        state.detail.data.items.push({
          reference: "",
          amount: 0,
          note: "",
        });
      } else {
        state.create.data.push({
          reference: "",
          amount: 0,
          note: "",
        });
      }
    },
    deleteColumn: (state, { payload }) => {
      if (payload.edit) {
        const index = state.detail.data.items.findIndex(
          (_, key) => key === payload.index
        );
        if (index !== -1) {
          state.detail.data.items.splice(index, 1);
        }
      } else {
        const index = state.create.data.findIndex(
          (_, key) => key === payload.index
        );
        if (index !== -1) {
          state.create.data.splice(index, 1);
        }
      }
    },
    updateColumn: (state, { payload }) => {
      if (payload.edit) {
        state.detail.data.items[payload.index][payload.key] = payload.value;
      } else {
        state.create.data[payload.index][payload.key] = payload.value;
      }
    },
    updateData: (state, { payload }) => {
      if (payload.edit) {
        state.detail.data[payload.key] = payload.value;
      } else {
        state.create[payload.key] = payload.value;
      }
    },
    setModalResult: (state, { payload }) => {
      state.create.result = payload;
    },
    setModalDetail: (state, { payload }) => {
      state.detail.modal = payload;
    },
    resetCreate: (state) => {
      const initial = {
        date: new Date(),
        book_type: {
          value: null,
          label: null,
        },
        payment_type: {
          value: null,
          label: null,
        },
        note: "",
        transaction_note: "",
        result: false,
        error: "",
        data: [
          {
            reference: "",
            amount: 0,
            note: "",
          },
        ],
      };
      state.create = initial;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCashBasis.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getCashBasis.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCashBasis.rejected, (state) => {
      state.loading = false;
      state.total = 0;
      state.data = [];
    });

    builder.addCase(getCashBasisDetail.fulfilled, (state, { payload }) => {
      state.detail.loading = false;
      state.detail.data = payload.data;
      state.detail.modal = true;
    });
    builder.addCase(getCashBasisDetail.pending, (state) => {
      state.detail.loading = true;
    });
    builder.addCase(getCashBasisDetail.rejected, (state) => {
      state.detail.loading = false;
      state.detail.data = null;
      state.detail.modal = false;
    });

    builder.addCase(createCashBasis.fulfilled, (state) => {
      state.loading = false;
      state.create.result = true;
      state.create.error = "";
    });
    builder.addCase(createCashBasis.pending, (state) => {
      state.loading = true;
      state.create.error = "";
    });
    builder.addCase(createCashBasis.rejected, (state, { payload }) => {
      state.loading = false;
      state.create.result = true;
      state.create.error = `${
        payload.message
          ? JSON.stringify(payload.message)
          : JSON.stringify(payload.data)
      }`;
    });

    builder.addCase(updateCashBasis.fulfilled, (state) => {
      state.detail.loading = false;
      state.detail.error = "";
      state.detail.modal = false;
    });
    builder.addCase(updateCashBasis.pending, (state) => {
      state.detail.loading = true;
      state.detail.error = "";
    });
    builder.addCase(updateCashBasis.rejected, (state, { payload }) => {
      state.detail.loading = false;
      state.detail.error = `${
        payload.message
          ? JSON.stringify(payload.message)
          : JSON.stringify(payload.data)
      }`;
    });
  },
});
export const {
  addNewColumn,
  deleteColumn,
  updateColumn,
  updateData,
  resetCreate,
  setModalResult,
  setModalDetail,
} = cashierSlice.actions;
export default cashierSlice.reducer;
