import moment from "moment";

export const isValidExchange = (isGift, date) => {
  const today = moment();
  const givenDate = moment(date);

  // if (paymentMethod === "BUY AS A GIFT") {
  if (isGift === 1) {
    const futureDate = givenDate.add(7, "days");
    const isValidDate = futureDate.isSameOrAfter(today);
    if (isValidDate) {
      return true;
    }
  } else {
    const futureDate = givenDate.add(3, "days");
    const isValidDate = futureDate.isSameOrAfter(today);
    if (isValidDate) {
      return true;
    }
  }
  return false;
};
