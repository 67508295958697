import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setDate, setNote, setStore } from "src/redux/store/transferStockOut";
import { toggleModalFollowUp } from "src/redux/store/transferStockIn";
import Select from "react-select";
import Table from "src/core/pagination/datatable";
import { Calendar } from "react-feather";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionsSelosyLogerro = [
  { value: "choose", label: "Choose" },
  { value: "selosy", label: "Selosy" },
  { value: "logerro", label: "Logerro" },
];

const FollowUpModal = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const transferStockIn = useSelector((state) => state.transferStockIn);

  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => index + 1,
    },
    {
      title: "Product Number",
      dataIndex: "id",
    },
    {
      title: "Product Name",
      dataIndex: "title",
    },
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Received Qty",
      dataIndex: "qty",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Note",
      dataIndex: "size",
    },
  ];

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockIn.modalFollowUp}
      onHide={() => dispatch(toggleModalFollowUp())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Create New Surat Keluar Barang</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg">
            <div className="input-blocks">
              <label>Created Date</label>
              <div className="input-blocks">
                <Calendar className="info-img" />
                <div className="input-groupicon">
                  <DatePicker
                    selected={transferStockOut.addItem.createdDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Choose Date"
                    className="datetimepicker"
                    wrapperClassName="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="input-blocks">
              <label>Reference</label>
              <Select
                classNamePrefix="react-select"
                options={optionsSelosyLogerro}
                value={transferStockOut.addItem.store}
                onChange={(e) => dispatch(setStore(e))}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-blocks">
              <label>From Store</label>
              <Select
                classNamePrefix="react-select"
                options={optionsSelosyLogerro}
                value={transferStockOut.addItem.store}
                onChange={(e) => dispatch(setStore(e))}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks">
              <label>Note</label>
              <textarea
                className="form-control"
                value={transferStockOut.addItem.note}
                onChange={(e) => dispatch(setNote(e.target.value))}
              ></textarea>
            </div>
          </div>
        </div>
        {/* <form onSubmit={handleAddProduct}>
          <div className="row align-items-end">
            <div className="col-lg-4">
              <div className="mb-3">
                <label>Product</label>
                <SelectAsync
                  classNamePrefix="react-select"
                  cacheOptions
                  loadOptions={loadProduct}
                  onChange={handleChange}
                  placeholder="Type product"
                  value={{
                    label: item.title,
                    value: item.id,
                  }}
                  // options={customers}
                  // className="select-override"
                  // options={products}
                  // defaultValue={defaultOptions}
                  // defaultOptions={defaultOptions}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-blocks">
                <label>Size</label>
                <Select
                  classNamePrefix="react-select"
                  options={size}
                  value={{
                    label: item.size,
                    value: item.size,
                  }}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      size: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="input-blocks">
                <label>Qty</label>
                <input
                  type="number"
                  className="form-control"
                  min={1}
                  value={item.qty}
                  onFocus={() => {
                    if (item.qty == 0) {
                      setItem({
                        ...item,
                        qty: "",
                      });
                    }
                  }}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      qty: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col mb-3">
              <button
                type="submit"
                className="btn btn-submit d-flex align-items-center gap-1"
              >
                <PlusCircle size={14} />
                <span>Add Product</span>
              </button>
            </div>
          </div>
        </form> */}

        <Table
          className="table datanew"
          columns={columns}
          dataSource={transferStockOut.addItem.data}
          //   loading={transferStockOut.loading}
          //   total={transferStockOut.total}
          pagination={false}
          // handleChange={handleChange}
        />
        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            onClick={() => dispatch(toggleModalFollowUp())}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-submit">
            Create
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FollowUpModal;
