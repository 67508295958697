import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVisitor, updateVisitor } from "src/redux/store/pos";

export default function VisitorCounter() {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVisitor());
  }, [dispatch]);

  const handleClick = (method, type) => {
    dispatch(
      updateVisitor({
        type,
        method,
      })
    );
  };

  return (
    <div className="visitor-counter">
      <span className="fw-bold">Visitor Counter</span>
      <div>{moment().format("LL")}</div>

      <div className="d-inline-block">
        <div className="d-flex align-items-center gap-3 mt-3">
          <span
            className="las la-female fs-3"
            style={{ color: "#fc037b" }}
          ></span>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            disabled={pos.visitor.female === 0}
            onClick={() => handleClick("minus", "Female")}
          >
            <span className="las la-minus"></span>
          </button>

          <div className="d-flex gap-2 align-items-center justify-content-center number-counter">
            {pos.visitor.female}
          </div>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            onClick={() => handleClick("plus", "Female")}
          >
            <span className="las la-plus"></span>
          </button>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <span
            className="las la-male fs-3"
            style={{ color: "#0377fc" }}
          ></span>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            disabled={pos.visitor.male === 0}
            onClick={() => handleClick("minus", "Male")}
          >
            <span className="las la-minus"></span>
          </button>

          <div className="d-flex gap-2 align-items-center justify-content-center number-counter">
            {pos.visitor.male}
          </div>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            onClick={() => handleClick("plus", "Male")}
          >
            <span className="las la-plus"></span>
          </button>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <span className="las la-child fs-3"></span>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            disabled={pos.visitor.kids === 0}
            onClick={() => handleClick("minus", "Kids")}
          >
            <span className="las la-minus"></span>
          </button>

          <div className="d-flex gap-2 align-items-center justify-content-center number-counter">
            {pos.visitor.kids}
          </div>

          <button
            className="btn btn-sm btn-primary"
            role="button"
            onClick={() => handleClick("plus", "Kids")}
          >
            <span className="las la-plus"></span>
          </button>
        </div>
      </div>
    </div>
  );
}
