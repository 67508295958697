import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "./modal.css";
import { Link } from "react-router-dom";
import { CheckCircle, HelpCircle } from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalRetur,
  setModalReturOptions,
  setReturType,
  resetDataRetur,
  setModalExchangeReason,
  resetChecked,
} from "src/redux/store/transaction";
import BackButton from "./retur/BackButton";

const ReturTableRow = ({ item }) => {
  // const [check, setChecked] = useState(item.checked);
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleCheck = (product_code) => {
    const temp = transaction.dataRetur.map((item) => {
      if (item.product_code == product_code) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(setReturData(temp));
  };

  const handleQty = (product_code, qty) => {
    const temp = transaction.dataRetur.map((item) => {
      if (item.qty_retur_outstanding >= qty) {
        // if (qty == 0) {
        //   return {
        //     ...item,
        //     qty_retur: 1,
        //   };
        // } else {
        // }
        if (item.product_code == product_code) {
          return {
            ...item,
            qty_retur: parseInt(qty),
          };
        } else {
          return {
            ...item,
          };
        }
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(setReturData(temp));
  };

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          onChange={() => handleCheck(item.product_code)}
          checked={item.checked}
        />
      </td>
      <td>
        <div onClick={() => handleCheck(item.product_code)}>
          <div className="fw-bold user-select-none">{item.product_code}</div>
          <div className="text-muted user-select-none">
            <small>{item.product_name}</small>
          </div>
          <div className="text-muted user-select-none">
            <small>{item.product_alias}</small>
          </div>
          <div className="text-muted user-select-none">
            <small>{item.product_size}</small>
          </div>
        </div>
      </td>
      <td>{item.qty}</td>
      <td className="text-center">{item.qty_retur_outstanding}</td>
      <td>
        <input
          type="number"
          className="form-control"
          min={1}
          max={item.qty_retur_outstanding}
          value={item.qty_retur}
          onChange={(e) => handleQty(item.product_code, e.target.value)}
        />
      </td>
    </tr>
  );
};

const Retur = () => {
  const transaction = useSelector((state) => state.transaction);
  const [holdRetur, setHoldRetur] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (transaction.dataRetur.length > 0) {
      const check = transaction.dataRetur.every((item) => item.checked == true);
      const checkSome = transaction.dataRetur.some(
        (item) => item.checked == true
      );
      // check if qty retur is valid
      // const qtySome = transaction.dataRetur.every(
      //   (item) => item.qty_retur == null || !isNaN(item.qty_retur)
      // );
      const filtered = transaction.dataRetur.filter(
        (item) => item.checked == true
      );
      const qtySome = filtered.every((item) => item.qty_retur > 0);
      if (checkSome && qtySome) {
        setValid(true);
      } else {
        setValid(false);
      }
      if (check) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    }
  }, [transaction]);

  useEffect(() => {
    if (checkedAll) {
      const check = transaction.dataRetur.every((item) => item.checked == true);
      if (!check) {
        const temp = transaction.dataRetur.map((item) => {
          return {
            ...item,
            checked: true,
          };
        });
        dispatch(setReturData(temp));
      }
      // console.log("check ", check);
    }
  }, [dispatch, checkedAll, transaction]);

  const handleCheckedAll = () => {
    if (checkedAll) {
      const temp = transaction.dataRetur.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      dispatch(setReturData(temp));
    }
    setCheckedAll(!checkedAll);
  };

  const handleConfirmRetur = (e) => {
    e.preventDefault();
    dispatch(setModalExchangeReason(true));
    dispatch(setModalRetur(false));
    // dispatch(closeModalTransaction());
    // dispatch(toggleModalCompletedRetur());
  };

  const handleBack = () => {
    setCheckedAll(false);
    dispatch(resetChecked());
    dispatch(setModalRetur(false));
    dispatch(setModalReturOptions(true));
    dispatch(setReturType(""));
    // dispatch(resetDataRetur());
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalRetur}
      // onHide={handleBack}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <BackButton handleClick={handleBack} />
      <Modal.Body>
        <div className="mt-5">
          <h4>Select item to {transaction.returType}</h4>
        </div>
        {/* <p className="mb-0">
          Do you want to Print Receipt for the Completed Order
        </p> */}

        <div className="p-3 mt-3">
          <table className="w-100 retur-table">
            <thead className="border-bottom">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={checkedAll}
                    onChange={handleCheckedAll}
                  />
                </th>
                <th>Product</th>
                <th>Qty</th>
                <th>Qty Outstanding</th>
                <th>Qty Retur</th>
              </tr>
            </thead>
            <tbody>
              {transaction.dataRetur.map((item, key) => (
                <ReturTableRow key={key} item={item} />
              ))}
            </tbody>
          </table>
        </div>

        <form onSubmit={handleConfirmRetur}>
          <div className="modal-footer d-sm-flex justify-content-between">
            <button
              type="submit"
              disabled={!valid}
              className="btn btn-secondary flex-fill"
              onClick={handleConfirmRetur}
            >
              Next
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default Retur;
