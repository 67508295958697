import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setModalCheckIn, setModalScanCheckOut } from "src/redux/store/user";

const ModalCheckIn = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModalCheckIn(false));
    dispatch(setModalScanCheckOut(false));
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={user.modalCheckIn}
      // onHide={() => dispatch(toggleModalCardPayment())}
    >
      <Modal.Header>
        <Modal.Title>Check {user.absenLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user.loading ? (
          <p className="text-center">Processing..</p>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            {user.absenErrorMessage ? (
              <div className="d-inline-block text-center">
                <span className="las la-exclamation-circle fs-1 text-danger"></span>
                <p className="mt-3">{user.absenErrorMessage}</p>
              </div>
            ) : (
              <div className="d-inline-block text-center">
                <span className="las la-check-circle fs-1 text-success"></span>
                <h5 className="mt-3">Check {user.absenLabel} Success</h5>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {!user.loading ? (
        <Modal.Footer className="d-flex justify-content-center gap-1">
          <button
            className="btn btn-sm btn-primary"
            type="submit"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default ModalCheckIn;
