import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCashSplitBill,
  setPaymentMethodSplitBill,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "./modal.css";
import { Minus, Plus } from "react-feather";
import {
  setModalTransaction,
  setReturType,
  setReturReason,
  setReturReasonNote,
  incrementQuantity,
  decrementQuantity,
  setModalExchangeComplete,
  resetDataRetur,
  resetReturCart,
} from "src/redux/store/transaction";
import { cancelAllSplitBill } from "src/redux/store/pos";
import { rupiahCurrency } from "src/helper/currency";
import CloseButton from "./retur/CloseButton";
import { PAYMENT_METHOD } from "src/config/constants";

const SplitBill = () => {
  const pos = useSelector((state) => state.pos);
  const [amountDisplay, setAmountDisplay] = useState(0);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue == "") {
      inputValue = "0";
    }

    // Remove any characters that are not digits or a single decimal point
    inputValue = inputValue.replace(/[^0-9,]/g, "");

    dispatch(setCashSplitBill(parseInt(inputValue)));
    setAmountDisplay(rupiahCurrency.format(inputValue).replace(",", "."));
  };

  const handleFocus = (e) => {
    if (pos.cash == 0) {
      dispatch(setCashSplitBill(""));
    }
  };

  const handleClose = () => {
    dispatch(cancelAllSplitBill());
  };

  useEffect(() => {
    if (pos.modalSplitBill) {
      setAmountDisplay(0);
    }
  }, [pos.modalSplitBill]);

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalSplitBill}
    >
      <Modal.Body>
        <CloseButton handleClick={handleClose} />
        <div className="px-3 mt-5 exchange-summary">
          <h2 className="mb-2">
            <span className="text-capitalize"></span> Total{" "}
            {rupiahCurrency.format(pos.totalAmount - pos.totalDiscount)}
          </h2>

          <p>Split payment</p>
          <div className="d-flex flex-column position-relative">
            <label className="form-label">
              Payment {pos.splitBill.data.length + 1}
            </label>
            <input
              className="form-control"
              placeholder="Amount"
              value={amountDisplay}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {pos.splitBill.data.length ? (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining : {rupiahCurrency.format(pos.splitBill.remaining)}
              </span>
            ) : (
              <span className="remaining-amount-split mt-3 text-muted">
                Remaining :{" "}
                {rupiahCurrency.format(
                  pos.totalAmount - pos.totalDiscount - pos.splitBill.cash
                )}
              </span>
            )}
          </div>

          {pos.splitBill.change < 0 && (
            <div className="d-flex align-items-center justify-content-between my-3">
              <div className="d-flex gap-3 align-items-center">
                <h5>Change</h5>
              </div>

              <h5>{rupiahCurrency.format(Math.abs(pos.splitBill.change))}</h5>
            </div>
          )}

          {pos.splitBill.cash > 0 && (
            <div className="d-flex flex-column gap-2 my-3">
              {PAYMENT_METHOD.map((item, key) => (
                <button
                  className={`btn btn-outline-secondary`}
                  key={key}
                  onClick={() => {
                    dispatch(
                      setPaymentMethodSplitBill({
                        paymentMethod: item,
                        total: pos.splitBill.cash,
                        status: false,
                      })
                    );
                    setAmountDisplay(0);
                  }}
                  // onClick={() => dispatch(setPaymentMethod(item))}
                >
                  {item}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* <div className="modal-footer d-sm-flex justify-content-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary flex-fill fw-bold fs-5"
            onClick={handleFinish}
          >
            <span>Done</span>
          </button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default SplitBill;
