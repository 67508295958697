import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalCheckOut,
  setModalScanCheckOut,
  storeAbsen,
} from "src/redux/store/user";
import TimeDifferenceTimer from "./Timer";

export default function CheckIn({ setChecked, item }) {
  const dispatch = useDispatch();
  const store = JSON.parse(localStorage.getItem("store"));
  const user = useSelector((store) => store.user);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const txtUserId = item.user_id;
  //   const txtDate = moment().format("YYYY-MM-DD");
  //   const txtCheckIn = item.check_in;
  //   const txtCheckOut = moment().format("HH:mm:ss");
  //   const payload = {
  //     txtUserId,
  //     txtDate,
  //     txtCheckIn,
  //     txtCheckOut,
  //     txtWarehouseId: store.store,
  //   };
  //   dispatch(storeAbsen(payload));
  // };
  const handleSubmit = (e) => {
    dispatch(setModalScanCheckOut(true));
  };

  return (
    <div className="mb-3">
      <div className="absen-wrapper">
        <div className="px-2">
          <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center px-2 py-2">
            <div className="d-flex align-items-center gap-2">
              <div className="fw-bold fs-5">{item.user.name}</div>
              {item.status == "check_out" && (
                <div className="badge badge-success">checked out</div>
              )}
            </div>
            <small>{item.date}</small>
          </div>
          <div className="px-2 pt-2 pb-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <small>Check in</small>
                <div className="fs-6">{item.check_in}</div>
              </div>

              {item.status == "check_out" && (
                <div className="absen-border-left ps-3">
                  <small>Check out</small>
                  <div className="fs-6">{item.check_out}</div>
                </div>
              )}

              {item.status == "check_in" && (
                <div className="absen-border-left ps-3">
                  <small>Work duration</small>
                  <div className="fs-6">
                    <TimeDifferenceTimer
                      key={`timer_${item.user_id}`}
                      initialTime={item.check_in}
                    />
                  </div>
                </div>
              )}

              {item.status == "check_out" && (
                <div className="absen-border-left ps-3">
                  <small>Working hours</small>
                  <div className="fs-6 fw-bold">{item.working_hours}</div>
                </div>
              )}

              {item.status == "check_in" && (
                <button
                  className="btn btn-primary mt-3 mt-xl-0"
                  disabled={user.loading}
                  onClick={handleSubmit}
                >
                  Checkout
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
