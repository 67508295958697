import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "./modal.css";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import {
  closeModalCompletedRetur,
  toggleModalCompletedRetur,
  toggleModalPrints,
  toggleModalReprint,
} from "src/redux/store/transaction";

const CompletedRetur = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //   };

  return (
    <Modal
      id="payment-completed"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalCompletedRetur}
      onHide={() => dispatch(toggleModalCompletedRetur())}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <Modal.Body className="text-center">
        <div className="icon-head">
          <Link to="#">
            <CheckCircle className="feather-40" />
          </Link>
        </div>
        <h4>Retur Completed</h4>
        {/* <p className="mb-0">
          Do you want to Print Receipt for the Completed Order
        </p> */}
        <div className="modal-footer d-sm-flex justify-content-between">
          <button
            className="btn btn-secondary flex-fill"
            onClick={() => dispatch(closeModalCompletedRetur())}
          >
            Close
          </button>
        </div>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default CompletedRetur;
