import React from "react";
import { Link } from "react-router-dom";
import Absen from "./absen";
import CheckIn from "./check-in";
import { useSelector } from "react-redux";
import ModalCheckIn from "./user-select/ModalCheckIn";
import ModalScanCheckout from "./check-in/ModalScanCheckOut";

const Dashboard = () => {
  const users = JSON.parse(localStorage.getItem("userData"));
  const user = useSelector((store) => store.user);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="col-12 col-md-6">
          <div className="alert alert-secondary">
            <div className="fw-bold fs-5">Welcome, {users?.name}</div>
          </div>
          <Absen />
        </div>

        <div className="row mt-3">
          {user.listAbsen
            // .filter((item) => item.check_out === null)
            .map((item, key) => (
              <div key={key} className="col-12 col-md-6 col-lg-4">
                <CheckIn item={item} />
              </div>
            ))}
        </div>

        {/* <div className="jumbotron p-5 bg-secondary border rounded-3">
          <div className="container">
            <h1 className="display-6 fw-bold text-light">Welcome</h1>
            <p className="col-md-8 fs-6 text-light">
              {process.env.REACT_APP_NAME} Dashboard
            </p>
            <Link to={"/pos"} className="btn btn-primary btn-lg">
              POS Page
            </Link>
          </div>
        </div> */}
      </div>

      <ModalCheckIn />
      <ModalScanCheckout />
    </div>
  );
};

export default Dashboard;
