import React from "react";
import { PlusCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleModalAdd } from "src/redux/store/transferStockOut";
const mainTitle = "Cashier List";
const subtitle = "Manage your cashier list";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="page-header">
      <div className="add-item d-flex">
        <div className="page-title">
          <h4>{mainTitle}</h4>
          <h6>{subtitle}</h6>
        </div>
      </div>
      <div className="page-btn">
        <a
          className="btn btn-added"
          role="button"
          onClick={() => navigate("/cashier/cash-basis/create")}
        >
          <PlusCircle className="me-2" />
          Create
        </a>
      </div>
    </div>
  );
};

export default Header;
