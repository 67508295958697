import React, { useState, useEffect } from "react";

const TimeDifferenceTimer = ({ initialTime }) => {
  // Parse the initial time string into hours, minutes, and seconds
  const parseTimeString = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return { hours, minutes, seconds };
  };

  // Convert the initial time into a Date object (for the current day)
  const getInitialTimeDate = () => {
    const now = new Date();
    const { hours, minutes, seconds } = parseTimeString(initialTime);
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes,
      seconds
    );
  };

  // Function to calculate the difference in time between now and the initial time
  const calculateTimeDifference = () => {
    const now = new Date();
    const initialTimeDate = getInitialTimeDate();
    const diffInSeconds = Math.floor((now - initialTimeDate) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return {
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  };

  // State to store the time difference (hours, minutes, and seconds)
  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference()
  );

  // useEffect to update the time difference every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {/* Display the time difference in HH:MM:SS */}
      <h5>
        {timeDifference.hours}:{timeDifference.minutes}:{timeDifference.seconds}
      </h5>
    </div>
  );
};

export default TimeDifferenceTimer;
