import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "../modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  setModalExchangeComplete,
  setModalExchangeSummary,
} from "src/redux/store/transaction";
import { receiptCurrency } from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./BackButton";
import Select from "react-select";
import { getProductList } from "src/redux/store/stockopname";

const reasonOptions = [
  {
    label: "--Choose Reason--",
    value: null,
  },
  {
    label: "Size was too small",
    value: "Size was too small",
  },
  {
    label: "Size was too large",
    value: "Size was too large",
  },
  {
    label: "Customer changed their mind",
    value: "Customer changed their mind",
  },
  {
    label: "Item not as descibed",
    value: "Item not as descibed",
  },
  {
    label: "Received wrong item",
    value: "Received wrong item",
  },
  {
    label: "Damaged or defective",
    value: "Damaged or defective",
  },
  {
    label: "Style",
    value: "Style",
  },
];

const ReturTableRow = ({ item }) => {
  // const [check, setChecked] = useState(item.checked);
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleCheck = (product_code) => {
    const temp = transaction.dataRetur.map((item) => {
      if (item.product_code == product_code) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(setReturData(temp));
  };

  const handleQty = (product_code, qty) => {
    const temp = transaction.dataRetur.map((item) => {
      if (item.product_code == product_code) {
        return {
          ...item,
          qty: parseInt(qty),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(setReturData(temp));
  };

  return (
    <tr>
      <td>
        <div>
          <div className="fw-bold user-select-none">{item.product_code}</div>
          <div className="text-muted user-select-none">
            <small>{item.product_name}</small>
          </div>
          <div className="text-muted user-select-none">
            <small>{item.product_alias}</small>
          </div>
          <div className="text-muted user-select-none">
            <small>{item.product_size}</small>
          </div>
        </div>
      </td>
      <td>{item.qty_retur}</td>
    </tr>
  );
};

const ReturReason = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const handleConfirmRetur = (e) => {
    e.preventDefault();
    dispatch(closeModalTransaction());
    dispatch(toggleModalCompletedRetur());
  };

  const handleBackProductList = () => {
    dispatch(setReturReason(null));
    dispatch(setModalExchangeReason(false));
    dispatch(setModalRetur(true));
  };

  const handleClickNext = () => {
    dispatch(setModalProductSelect(true));
    dispatch(setModalExchangeReason(false));
    dispatch(getProductList());
  };

  const handleReturnComplete = () => {
    dispatch(setModalExchangeSummary(true));
    dispatch(setModalExchangeReason(false));
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalExchageReason}
      //   onHide={handleBackProductList}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <BackButton handleClick={handleBackProductList} />
      <Modal.Body>
        <div className="mt-5">
          <h4>Add return reason</h4>
        </div>

        <div className="p-3 mt-3">
          <table className="w-100 retur-table">
            <thead className="border-bottom">
              <tr>
                <th>SKU</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {transaction.dataRetur
                .filter((item) => item.checked === true)
                .map((item, key) => (
                  <ReturTableRow key={key} item={item} />
                ))}
            </tbody>
          </table>
        </div>

        <div className="px-3 mb-3">
          <label className="form-label">Reason</label>
          <Select
            classNamePrefix="react-select"
            options={reasonOptions}
            placeholder={"Choose reason"}
            value={{
              label: transaction.returReason,
              value: transaction.returReason,
            }}
            onChange={(e) => dispatch(setReturReason(e.value))}
          />
        </div>

        {transaction.returReason && (
          <div className="px-3 mb-3">
            <label className="form-label">Note</label>
            <input
              type="text"
              className="form-control"
              placeholder="Reason note"
              onChange={(e) => dispatch(setReturReasonNote(e.target.value))}
            />
          </div>
        )}

        <form onSubmit={handleConfirmRetur}>
          <div className="modal-footer d-sm-flex justify-content-center">
            {/* {transaction.returReason ? (
              transaction.returType == "return" ? (
                <button
                  type="button"
                  className="btn btn-secondary flex-fill"
                  onClick={handleReturnComplete}
                >
                  Confirm Return
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary flex-fill"
                  onClick={handleClickNext}
                >
                  Next
                </button>
              )
            ) : transaction.returType == "return" ? (
              <button
                type="button"
                className="btn btn-outline-warning flex-fill"
                onClick={handleReturnComplete}
              >
                Confirm Return
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-warning flex-fill"
                onClick={handleClickNext}
              >
                Skip
              </button>
            )} */}
            {transaction.returType == "return" ? (
              <button
                type="button"
                className="btn btn-secondary flex-fill"
                onClick={handleReturnComplete}
                disabled={!transaction.returReason}
              >
                Confirm Return
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-secondary flex-fill"
                onClick={handleClickNext}
                disabled={!transaction.returReason}
              >
                Next
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default ReturReason;
