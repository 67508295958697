import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Calendar } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Divider, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewColumn,
  createCashBasis,
  deleteColumn,
  resetCreate,
  updateColumn,
  updateData,
} from "src/redux/store/cashier";
import { rupiahCurrency } from "src/helper/currency";
import { isValid } from "date-fns";
import moment from "moment";
import ResultModal from "./ResultModal";

const bookTypes = [
  {
    value: 1,
    label: "Pengeluaran",
  },
  {
    value: 2,
    label: "Penerimaan",
  },
];

export default function CashBasisCreate() {
  const [valid, setValid] = useState(false);
  const cashier = useSelector((state) => state.cashier);
  const dispatch = useDispatch();
  const handleClickNew = () => {
    dispatch(addNewColumn({ edit: false }));
  };
  const handleClickDelete = (index) => {
    dispatch(deleteColumn({ index }));
  };
  const handleChange = (key, value, index) => {
    dispatch(updateColumn({ key, value, index }));
  };

  const handleChangeAmount = (value, index) => {
    let inputValue = value;
    if (inputValue == "") {
      inputValue = "0";
    }
    inputValue = inputValue.replace(/[^0-9,]/g, "");
    dispatch(
      updateColumn({ key: "amount", value: parseInt(inputValue), index })
    );
  };

  useEffect(() => {
    const checkData = cashier.create.data.every(
      (item) => item.reference !== "" && item.amount > 0
    );
    if (checkData && cashier.create.date && cashier.create.book_type.value) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [cashier]);

  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            placeholder="Enter reference"
            defaultValue={item.reference}
            onChange={(e) => handleChange("reference", e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            value={rupiahCurrency.format(item.amount).replace(",", ".")}
            onChange={(e) => handleChangeAmount(e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (_, item, index) => {
        return (
          <input
            className="form-control"
            placeholder="Enter note"
            defaultValue={item.note}
            onChange={(e) => handleChange("note", e.target.value, index)}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "note",
      render: (_, item, index) => {
        return index == 0 ? (
          <div className="d-grid">
            <button className="btn btn-info" onClick={handleClickNew}>
              <span className="las la-plus fs-5"></span>
            </button>
          </div>
        ) : (
          <div className="d-grid">
            <button
              className="btn btn-outline-danger"
              onClick={() => handleClickDelete(index)}
            >
              <span className="las la-trash fs-5"></span>
            </button>
          </div>
        );
      },
    },
  ];

  const handleSubmit = () => {
    const payload = {
      // ...cashier.create,
      // // payment_type: cashier.create.payment_type.value,
      txtTransaksiDate: moment(cashier.create.date).format("YYYY-MM-DD"),
      txtBuku: cashier.create.book_type.value,
      totalSum: cashier.create.data.reduce(
        (acc, item) => acc + parseInt(item.amount),
        0
      ),
      items: cashier.create.data,
    };
    dispatch(createCashBasis(payload));
  };

  useEffect(() => {
    dispatch(resetCreate());
  }, [dispatch]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Header />
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-blocks">
                  <label className="form-label">
                    Transaction Date <small className="text-danger">*</small>
                  </label>
                  <div className="input-blocks">
                    <Calendar className="info-img" />
                    <div className="input-groupicon d-grid">
                      <DatePicker
                        selected={cashier.create.date}
                        onChange={(date) =>
                          updateColumn({ key: "date", value: date })
                        }
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Choose Date"
                        className="datetimepicker"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-blocks">
                  <label className="form-label">
                    Book Type <small className="text-danger">*</small>
                  </label>
                  <Select
                    className="mt-2"
                    classNamePrefix="react-select"
                    options={bookTypes}
                    placeholder="Book type"
                    value={cashier.create.book_type}
                    onChange={(e) =>
                      dispatch(updateData({ key: "book_type", value: e }))
                    }
                  />
                </div>
              </div>

              {/* <div className="col-12 col-md-6 col-lg-3">
                <div className="input-blocks">
                  <label className="form-label">Note</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cashier.create.note}
                    onChange={(e) =>
                      dispatch(
                        updateData({ key: "note", value: e.target.value })
                      )
                    }
                  />
                </div>
              </div> */}
            </div>

            <Divider variant="dashed" style={{ borderColor: "#7cb305" }} dashed>
              Transaction Detail
            </Divider>

            <div className="row pb-5">
              <div className="col-12">
                <div className="table-responsive">
                  <Table
                    tableClassName={"tight-table"}
                    className="table datanew"
                    columns={columns}
                    dataSource={cashier.create.data}
                    // loading={transferStockOut.loading}
                    // total={transferStockOut.total}
                    // handleChange={handleChange}
                    // pageSize={limit}
                    // rowKey={record => record.reference}
                    pagination={false}
                  />
                </div>
              </div>
            </div>

            {/* <div className="d-grid">
              <div className="input-blocks">
                <label className="form-label">Transaction Note</label>
                <textarea
                  className="form-control"
                  value={cashier.create.transaction_note}
                  onChange={(e) =>
                    dispatch(
                      updateData({
                        key: "transaction_note",
                        value: e.target.value,
                      })
                    )
                  }
                ></textarea>
              </div>
            </div> */}
            <div className="d-flex justify-content-end mt-3">
              <div className="input-blocks">
                <label className="form-label">Total Transaction</label>
                <div className="fs-5 text-end">
                  {rupiahCurrency.format(
                    cashier.create.data.reduce(
                      (acc, item) => acc + parseInt(item.amount),
                      0
                    )
                  )}
                </div>
              </div>
            </div>

            <Divider></Divider>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary d-flex gap-2 align-items-center"
                disabled={cashier.loading || !valid}
                onClick={handleSubmit}
              >
                <span className="las la-save fs-5"></span>
                {cashier.loading ? `Processing` : `Submit`}
              </button>
            </div>
          </div>
        </div>
      </div>

      <ResultModal />
    </div>
  );
}
