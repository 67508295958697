import { useState, useEffect } from "react";

// Custom hook for user validation using localStorage
const useUserValidation = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [store, setStore] = useState(null);

  // Load user from localStorage when the component mounts
  useEffect(() => {
    const savedUser = localStorage.getItem("userData");
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  // Validate if the user is logged in (by checking if user exists)
  const isValidUser = () => {
    return user !== null;
  };

  // Save the user in localStorage
  const login = (userData, token, store) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("token", token);
    localStorage.setItem("store", JSON.stringify(store));
    setUser(userData);
    setToken(token);
    setStore(store);
  };

  // Remove the user from localStorage (log out)
  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    localStorage.removeItem("store");
    localStorage.removeItem("staff");
    setUser(null);
    setToken(null);
    setStore(null);
  };

  return { user, token, store, isValidUser, login, logout };
};

export default useUserValidation;
