import React from "react";
import { calculateVAT, receiptCurrency } from "src/helper/currency";
import Header from "./receipt/Header";
// import Footer from "./receipt/Footer";
import { useSelector } from "react-redux";

const PrintableComponent = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  return (
    // <div className="print-receipts-wrapper">
    <div className="printable-component" id="print-receipts">
      <Header grandTotal={0} />
      <div
        className="text-center fs-4 fw-bolder"
        style={{ marginBottom: "40px", marginTop: "40px" }}
      >
        Outgoing Goods
      </div>

      <table className="w-100 table-fit">
        <thead>
          <tr style={{ borderBottom: "1px solid #DDD" }}>
            <th
              className="small-font"
              style={{ width: "40%", paddingBottom: "10px" }}
            >
              Items
            </th>
            <th
              className="text-center small-font"
              style={{
                width: "20%",
                paddingBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Qty
            </th>
            <th
              className="text-center small-font"
              style={{
                width: "20%",
                paddingBottom: "10px",
                paddingRight: "10px",
              }}
            >
              Qty Ots
            </th>
            <th
              className="text-center small-font"
              style={{ width: "20%", paddingBottom: "10px" }}
            >
              Notes
            </th>
          </tr>
        </thead>
        <tbody className="content-row">
          {transferStockOut.detail.data.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != transferStockOut.detail.data.length - 1
                  ? `border-bottom-dashed`
                  : ``
              }`}
            >
              <td className="py-2">
                {/* <div className="fw-bold">{item.product_name}</div> */}
                <div className="fw-bold medium-font">
                  {item.product_alias} - [{item.product_size}]
                </div>
                {/* <div>{item.product_size}</div> */}
                <div className="medium-font">{item.product_code}</div>
              </td>
              <td
                className="text-center fw-bold fs-6 medium-font"
                style={{ paddingBottom: "10px", paddingRight: "15px" }}
              >
                {item.qty}
              </td>
              <td
                className="text-center fw-bold fs-6 medium-font"
                style={{ paddingBottom: "10px", paddingRight: "15px" }}
              >
                {item.qty_outstanding}
              </td>
              <td className="text-center medium-font">
                {item.stock_order_detail_note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-between align-items-start mt-4 gap-3 medium-font">
        <div className="d-inline-block">
          <div>
            <small>
              Created by : <strong>{transferStockOut.detail.created_by}</strong>
            </small>
          </div>
          <div>
            <small>
              Created at :{" "}
              <strong>{transferStockOut.detail.stock_order_date}</strong>
            </small>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end gap-2 flex-wrap medium-font">
          <div className="fw-bolder">Total :</div>
          <div>
            {transferStockOut.detail.data.reduce(
              (accumulator, item) => accumulator + parseInt(item.qty),
              0
            )}{" "}
            products
          </div>
        </div>
      </div>

      <div className="my-5">
        <label className="form-label">Notes</label>
        <div className="print-receipts-note medium-font">
          {transferStockOut.detail.stock_order_note}
        </div>
      </div>
      {/* <Footer billingCode={"[billing_code]"} transDate={"[billing_date]"} /> */}
    </div>
    // </div>
  );
};

export default PrintableComponent;
