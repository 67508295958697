import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PrintableComponent from "./PrintableComponent";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import {
  closeModalReprint,
  closeModalTransaction,
  toggleModalReprint,
} from "src/redux/store/transaction";
import { printReceipt } from "src/helper/print";
import { closeModalCompletedPayment, finishOrder } from "src/redux/store/pos";
import PrintPDFComponent from "./PrintPDFComponent";
import { printReceiptBlob } from "../../../../helper/print";
import useEpsonPrinter from "src/hooks/usePrinter";

const ReprintReceipt = () => {
  const printer = useEpsonPrinter();
  const [blobUrl, setBlobUrl] = useState(null);
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const blobToImage = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handlePrint = async () => {
    printReceipt("reprint-receipt");
    // const pdfBlob = printReceiptBlob("reprint-receipt");
    // const img = await blobToImage(pdfBlob);

    // if (printer) {
    //   printer.addText("Groot POS\n");
    //   printer.addCut(printer.CUT_FEED);
    //   printer.send();
    //   printer.print(img);
    // }
    dispatch(closeModalCompletedPayment());
    dispatch(closeModalTransaction());
    dispatch(closeModalReprint());
    dispatch(finishOrder());
  };

  return (
    <>
      <Modal
        id="print-receipt"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={transaction.modalReprint}
        onHide={() => {
          dispatch(toggleModalReprint());
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <PrintableComponent pos={transaction} />

            <div className="d-flex justify-content-center my-3">
              <div
                to="#"
                className="btn btn-primary non-printable"
                role="button"
                onClick={handlePrint}
              >
                Print Receipt
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      {/* <PrintPDFComponent blobUrl={blobUrl} /> */}
    </>
  );
};

export default ReprintReceipt;
