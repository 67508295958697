import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable";
import Swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import { getWarehouseList } from "src/redux/store/warehouse";
import {
   getStockMovement,
} from "src/redux/store/stockMovement";
import Header from "./Header"
import './style.css';

const renderStatus = (val) => {
   if (val == "created")
      return <span className="badge bg-warning">Created</span>;
   if (val == "partially received")
      return <span className="badge bg-secondary">Partially Received</span>;
   if (val == "close") return <span className="badge bg-success">Close</span>;
};

const StockMovementPage = () => {
   // declare untuk ambil data store
   const store = JSON.parse(localStorage.getItem("store"));
   
   const stockMovement = useSelector((state) => state.stockMovement);
   const dispatch = useDispatch();
   const [limit, setLimit] = useState(10);
   const [skip, setSkip] = useState(0);
   const [page, setPage] = useState(1);
   const [q, setQ] = useState("");
   const [search, setSearch] = useState("");
   const [warehouse_id, setWarehouseId] = useState(store.store);
   const warehouse = useSelector((state) => state.warehouse);

   const [isFilterVisible, setIsFilterVisible] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);

   useEffect(() => {
      dispatch(
         getStockMovement({
         limit,
         // offset: skip,
         page,
         warehouse_id,
         // data api yg di search
         search_product: q,
         order: "warehouse_id",
         })
      );
   }, [limit, skip, q, dispatch, page, warehouse_id]);

   
   useEffect(() => {
      dispatch(getWarehouseList());
   }, [dispatch]);

   const handleChangeWarehouse = (e) => {
      const warehouse_id = e.value;
      setWarehouseId(warehouse_id);
   };
   
   const toggleFilterVisibility = () => {
      setIsFilterVisible((prevVisibility) => !prevVisibility);
   };

   const handleDateChange = (date) => {
      setSelectedDate(date);
   };

   const handleChange = (pagination, filters, sorter) => {
      const offset =
         pagination.current * pagination.pageSize - pagination.pageSize;
      const limit = pagination.pageSize;

      setSkip(offset);
      setLimit(limit);
      setPage(pagination.current);
   };

   const columns = [
      {
         title: "No",
         key: "index",
         render: (value, item, index) => (page - 1) * limit + index + 1,
      },
      {
         title: "Date",
         dataIndex: "stock_order_date",
      },
      {
         title: "Number",
         dataIndex: "stock_order_code",
      },
      {
         title: "Type",
         dataIndex: "stock_order_reference",
      },
      {
         title: "Quantity",
         dataIndex: "total_qty",
      },
   ];

   const MySwal = withReactContent(Swal);

   const handleDelete = (item) => {
      MySwal.fire({
         title: `Cut off this data? <br/><code>${item.search_product}</code>`,
         text: "",
         showCancelButton: true,
         icon: "warning",
         // cancelButtonColor: "#ff0000",
         cancelButtonText: "Cancel",
         confirmButtonColor: "#00ff00",
         confirmButtonText: "Yes",
      }).then(async (result) => {
         if (result.isConfirmed) {
         const res = await dispatch(
            // searchProductCutOff({ stock_order_id: item.stock_order_id })
         ).unwrap();
         // console.log("confirmed");
         } else {
         // console.log("canceled");
         }
      });
   };

   const handleSearch = (e) => {
      e.preventDefault();
      setQ(search);
   };

   return (
    <div className="page-wrapper">
       <div className="content">
         <Header />
         {/* <h2>{store.store_name}</h2> */}

         <div className="card table-list-card">
            <div className="card-body">
               {/* Stock Movement Search */}
               <div className="table-top">
                  <div className="search-set">
                     <Select
                        classNamePrefix="react-select"
                        menuPortalTarget={document.body}
                        styles={{
                           menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={warehouse.warehouseList.map((item, key) => {
                           return {
                           value: item.warehouse_id,
                           label: item.warehouse_name,
                           };
                        })}
                        // value={{
                        //    value: warehouse_id,
                        //    label: store.store_name
                        // jadiin state store,name
                        // }}
                        onChange={(e) => handleChangeWarehouse(e)}
                        placeholder="Choose Store"
                     />
                  </div>
               </div>
               {/* Stock Movement Search */}

               {/* data movement stock */}
               <div className="table-responsive">
                  <h2 className="pl-5 mb-2">Stock Movement</h2>               
                  <Table
                     tableClassName={"tight-table"}
                     className="table datanew"
                     columns={columns}
                     dataSource={stockMovement.data}
                     loading={stockMovement.loading}
                     total={stockMovement.total}
                     handleChange={handleChange}
                     pagination={{ simple: false }}
                     pageSize={limit}
                  />
               </div>
               {/* data movement stock */}
            </div>
         </div>
       </div>
    </div>
  )
}

export default StockMovementPage