import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  finishOrder,
  toggleModalCompletedPayment,
  // togglePrintReceipt,
} from "src/redux/store/pos";
import "../modal.css";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CornerLeftDown,
  HelpCircle,
  Minus,
  MinusCircle,
  Plus,
  RefreshCcw,
} from "react-feather";
import {
  toggleModalPrints,
  toggleModalReprint,
  toggleModalRetur,
  toggleModalTransaction,
  toggleModalCompletedRetur,
  closeModalCompletedRetur,
  closeModalTransaction,
  setReturData,
  setModalTransaction,
  setModalReturOptions,
  setReturType,
  setModalRetur,
  resetDetailTransaction,
  setModalExchangeReason,
  setReturReason,
  setReturReasonNote,
  setModalProductSelect,
  setModalQtySelect,
  resetReturProduct,
  setModalNewItem,
  incrementQuantity,
  decrementQuantity,
  setExchangeNote,
  setModalExchangeSummary,
  setModalExchangeComplete,
  returProduct,
} from "src/redux/store/transaction";
import {
  calculateDiscount,
  receiptCurrency,
  rupiahCurrency,
} from "src/helper/currency";
import { CornerUpLeft } from "feather-icons-react/build/IconComponents";
import BackButton from "./BackButton";
import Select from "react-select";
import SearchProduct from "./SearchProduct";
import { PAYMENT_METHOD } from "src/config/constants";

const ExchangeSummary = () => {
  const transaction = useSelector((state) => state.transaction);
  const [payment, setPayment] = useState(null);
  const dispatch = useDispatch();

  const handleBackProductSelect = () => {
    if (transaction.returType == "exchange") {
      dispatch(setModalProductSelect(true));
      dispatch(setModalExchangeSummary(false));
      dispatch(resetReturProduct());
      setPayment(null);
    } else {
      dispatch(setModalExchangeReason(true));
      dispatch(setModalExchangeSummary(false));
    }
  };

  const handleExchangeComplete = () => {
    // dispatch(setModalExchangeSummary(false));
    // dispatch(setModalExchangeComplete(true));
    const staff = JSON.parse(localStorage.getItem("staff"));

    const filtered = transaction.dataRetur.filter(
      (item) => item.checked === true
    );
    const temp = filtered.map((item) => {
      return {
        product_code: item.product_code,
        product_size: item.product_size,
        qty: item.qty_retur,
      };
    });
    const exchange = transaction.returCart.map((item) => {
      return {
        product_code: item.product_code,
        product_size: item.product_size,
        qty: item.qty,
        price: item.product_price,
        disc: item.product_discount,
      };
    });
    const payload = {
      billingCode: transaction.detailTransaction.billingCode,
      returReason: transaction.returReason,
      returReasonNote: transaction.returReasonNote,
      returNote: transaction.exchangeNotes,
      returType: transaction.returType,
      returProduct: temp,
      exchangeProduct: exchange,
      totalAmountReturProduct: transaction.dataReturTotal,
      totalAmountExchangeProduct: transaction.returTotalAmount,
      totalRefund: transaction.totalRefund,
      txtPaid: transaction.totalRefund,
      txtPaidType: [
        {
          paymentMethod: payment,
          total: transaction.totalRefund,
        },
      ],
      user_id: staff?.id,
    };
    dispatch(returProduct(payload));
  };

  const renderButtonLabel = (transaction) => {
    if (transaction.returType == "exchange") {
      if (transaction.totalRefund < 0) {
        return "Difference";
      } else {
        return "Pay";
      }
    } else {
      return "Refund";
    }
  };

  return (
    <Modal
      id="payment-completed"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalExchangeSummary}
      //   onHide={handleBackProductSelect}
    >
      {/* <form onSubmit={handleSubmit}> */}
      <BackButton handleClick={handleBackProductSelect} />
      <Modal.Body>
        <div className="px-3 mt-5 exchange-summary">
          <h2 className="mb-2">
            <span className="text-capitalize">{transaction.returType}</span>{" "}
            Summary
          </h2>
          {transaction.errorRetur && (
            <div className="alert alert-outline-danger alert-dismissible fade show">
              {JSON.stringify(transaction.errorRetur)}
            </div>
          )}
          <div className="mt-3">
            <h5 className="mb-4 text-muted">Items</h5>

            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex gap-3 align-items-center">
                <span className="badge bg-secondary fs-5">
                  {/* {
                    transaction.dataRetur.filter(
                      (item) => item.checked === true
                    ).length
                  } */}
                  {transaction.dataRetur.reduce((acc, item) => {
                    if (item.checked) {
                      return acc + item.qty_retur;
                    }
                    return acc;
                  }, 0)}
                </span>
                <h5>Return Items</h5>
              </div>

              <h5>{rupiahCurrency.format(transaction.dataReturTotal)}</h5>
            </div>

            <div className="mb-3">
              <div className="accordion" id="accordionRetur">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="dataRetur">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseRetur"
                      aria-expanded="true"
                      aria-controls="collapseRetur"
                    >
                      Detail Retur Items
                    </button>
                  </h2>
                  <div
                    id="collapseRetur"
                    className="accordion-collapse collapse"
                    aria-labelledby="dataRetur"
                    data-bs-parent="#accordionRetur"
                  >
                    <div className="accordion-body">
                      {transaction.dataRetur.map((item, key) => {
                        if (item.checked) {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center gap-4"
                              key={key}
                            >
                              <div>
                                <div className="fw-bold user-select-none">
                                  {item.product_code}
                                </div>
                                <div className="text-muted user-select-none">
                                  <small>{item.product_name}</small>
                                </div>
                                <div className="text-muted user-select-none">
                                  <small>
                                    {item.product_alias} | ({" "}
                                    <span className="fw-bold">
                                      {item.product_size}
                                    </span>{" "}
                                    )
                                  </small>
                                </div>
                              </div>

                              <div className="d-flex gap-2 align-items-center">
                                {item.qty_retur}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {transaction.returType == "exchange" && (
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex gap-3 align-items-center">
                  <span className="badge bg-secondary fs-5">
                    {/* {transaction.returCart.length} */}
                    {transaction.returCart.reduce(
                      (acc, item) => acc + item.qty,
                      0
                    )}
                  </span>
                  <h5>New Items</h5>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <h5>
                    {rupiahCurrency.format(
                      transaction.returTotalAmount -
                        transaction.returTotalDiscount
                    )}
                  </h5>
                </div>
              </div>
            )}
            {transaction.returType == "exchange" && (
              <div className="mb-3">
                <div className="accordion" id="accordionExchange">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="dataExchange">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseExchange"
                        aria-expanded="true"
                        aria-controls="collapseExchange"
                      >
                        Detail New Items
                      </button>
                    </h2>
                    <div
                      id="collapseExchange"
                      className="accordion-collapse collapse"
                      aria-labelledby="dataExchange"
                      data-bs-parent="#accordionExchange"
                    >
                      <div className="accordion-body">
                        {transaction.returCart.map((item, key) => (
                          <div
                            className="d-flex justify-content-between align-items-center gap-4"
                            key={key}
                          >
                            <div>
                              <div className="fw-bold user-select-none">
                                {item.product_code}
                              </div>
                              <div className="text-muted user-select-none">
                                <small>{item.product_name}</small>
                              </div>
                              <div className="text-muted user-select-none">
                                <small>
                                  {item.product_alias} | ({" "}
                                  <span className="fw-bold">
                                    {item.product_size}
                                  </span>{" "}
                                  )
                                </small>
                              </div>
                            </div>

                            <div className="d-flex gap-2 align-items-center">
                              {item.qty}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {transaction.returType != "exchange" && (
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex gap-3 align-items-center">
                <h5>Amount to refund</h5>
              </div>

              <h5>{rupiahCurrency.format(transaction.dataReturTotal)}</h5>
            </div>
          )}
          {/* {transaction.returType == "exchange" && (
            <div className="mt-4">
              <h5 className="mb-4 text-muted">Payment type</h5>
              {transaction.detailTransaction?.billingPaidType?.map(
                (item, key) => (
                  <div
                    key={key}
                    className="d-flex align-items-center justify-content-between mb-3"
                  >
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="text-muted fw-light">
                        {item.payment_method}
                      </h5>
                    </div>

                    <h5>{rupiahCurrency.format(item.payment_amount)}</h5>
                  </div>
                )
              )}
            </div>
          )} */}
          {transaction.returType == "exchange" && (
            <div className="mt-4">
              <h5 className="mb-4 text-muted">Payment method</h5>
              <div className="d-flex flex-column gap-2 my-3">
                {PAYMENT_METHOD.map((item, key) => (
                  <button
                    className={`btn ${
                      item == payment
                        ? `btn-secondary`
                        : `btn-outline-secondary`
                    }`}
                    key={key}
                    onClick={() => {
                      setPayment(item);
                      // dispatch(
                      //   setPaymentMethodSplitBill({
                      //     paymentMethod: item,
                      //     total: pos.splitBill.cash,
                      //     status: false,
                      //   })
                      // );
                      // setAmountDisplay(0);
                    }}
                    // onClick={() => dispatch(setPaymentMethod(item))}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className="my-4">
            <h5 className="mb-4 text-muted">
              <span className="text-capitalize">{transaction.returType}</span>{" "}
              notes
            </h5>
            <input
              className="form-control"
              placeholder="Add note"
              value={transaction.exchangeNotes}
              onChange={(e) => dispatch(setExchangeNote(e.target.value))}
            />
          </div>
        </div>

        <div className="modal-footer d-sm-flex justify-content-center">
          {transaction.totalRefund >= 0 ? (
            <button
              type="button"
              className="btn btn-lg btn-secondary flex-fill fw-bold fs-5"
              onClick={handleExchangeComplete}
              disabled={
                transaction.loadingRetur ||
                (transaction.returType == "exchange" && !payment)
              }
            >
              {renderButtonLabel(transaction)}{" "}
              {rupiahCurrency.format(Math.abs(transaction.totalRefund))}
            </button>
          ) : (
            <div className="alert alert-warning d-flex flex-fill gap-2 align-items-center justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <span className="fa fa-info-circle flex-shrink-0 fs-5"></span>
                <span>
                  Total of new items must be greater than amount total of
                  exchange items <br />
                  <strong>
                    {rupiahCurrency.format(transaction.totalRefund)}
                  </strong>
                </span>
              </div>
              <button
                className="btn btn-primary"
                onClick={handleBackProductSelect}
              >
                Add more items
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
      {/* </form> */}
    </Modal>
  );
};

export default ExchangeSummary;
