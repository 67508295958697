import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PAYMENT_METHOD } from "src/config/constants";
import { rupiahCurrency } from "src/helper/currency";
import {
  cancelAllSplitBill,
  setBuyAsGift,
  setPaymentMethod,
  toggleModalPaymentMethod,
  toggleSelectedPaymentMethod,
  // toggleModalCompletedPayment,
} from "src/redux/store/pos";

const PaymentMethodModal = () => {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalPaymentMethod}
      onHide={() => dispatch(toggleModalPaymentMethod())}
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="text-center">Total Amount</div>
          {/* <h2 className="text-center ">4500.00</h2> */}
          <h2 className="text-center mt-2">
            {rupiahCurrency.format(pos.totalAmount - pos.totalDiscount)}
          </h2>
        </div>

        <div className="d-flex justify-content-center gap-2 align-items-center my-3">
          <input
            type="checkbox"
            className="form-check-label"
            id="gift"
            checked={pos.isGift == 1}
            onChange={() => dispatch(setBuyAsGift(pos.isGift == 1 ? 0 : 1))}
          />
          <label className="fs-6 user-select-none" htmlFor="gift">
            Buy as Gift
          </label>
        </div>

        <div className="d-flex flex-column gap-2 my-3">
          {PAYMENT_METHOD.map((item, key) => (
            <button
              className={`btn ${
                pos.paymentType == item
                  ? `btn-secondary`
                  : `btn-outline-secondary`
              }`}
              key={key}
              onClick={() => dispatch(setPaymentMethod(item))}
            >
              {item}
            </button>
          ))}
          {/* <button
            className={`btn ${
              pos.paymentType == "SPLIT BILL"
                ? `btn-secondary`
                : `btn-outline-secondary`
            }`}
            onClick={() => {
              dispatch(cancelAllSplitBill());
              dispatch(setPaymentMethod("SPLIT BILL"));
            }}
          >
            SPLIT BILL
          </button> */}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center gap-1">
        <Button
          variant="secondary"
          onClick={() => dispatch(toggleModalPaymentMethod())}
        >
          Back to product
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={!pos.paymentType}
          onClick={() => dispatch(toggleSelectedPaymentMethod())}
        >
          {`Next Step`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethodModal;
