import React from "react";
import {
  calculateVAT,
  receiptCurrency,
  rupiahCurrency,
} from "src/helper/currency";
import "./style.css";
import Footer from "../Footer";
import Header from "./Header";
import { useSelector } from "react-redux";

const PrintableComponent = () => {
  const transaction = useSelector((state) => state.transaction);
  return (
    <div className="printable-component p-5" id="reprint-receipt">
      <Header />
      <div className="my-4 text-center">
        <h4>Retur</h4>
        <div className="d-flex justify-content-center gap-1">
          <small>Reference :</small>
          <small>{transaction.detailRetur.billingCode}</small>
        </div>
      </div>
      <table className="w-100 table-fit">
        <thead>
          <tr>
            <th style={{ width: "80%" }}>Items</th>
            <th className="text-end">Qty</th>
          </tr>
        </thead>
        <tbody className="content-row">
          {transaction.detailRetur?.data?.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != transaction.detailRetur?.data.length - 1
                  ? `border-bottom-dashed`
                  : ``
              }`}
            >
              <td>
                <div className="d-flex flex-column">
                  <span className="fw-bold">
                    {item.product_alias} [{item.product_size}]
                  </span>
                  <span>{item.qty}</span>
                  <span style={{ fontSize: "10px" }}>{item.product_code}</span>
                </div>
              </td>
              <td className="text-end" style={{ verticalAlign: "top" }}>
                <div className="d-flex flex-column">
                  <div className="fw-bold">{item.quantity}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="my-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>Reason :</div>
          <div>{transaction.detailRetur.reason}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>Total Refund :</div>
          <div>
            {rupiahCurrency.format(transaction.detailRetur.totalRefund)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintableComponent;
