import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { receiptCurrency, rupiahCurrency } from "src/helper/currency";

const GrandTotal = () => {
  const staging = useSelector((state) => state.pos);
  return (
    <div className="d-grid btn-block">
      <Link className="btn btn-outline-secondary fs-5" to="#">
        <small>Grand Total :</small>{" "}
        {receiptCurrency.format(staging.totalAmount - staging.totalDiscount)}
      </Link>
    </div>
  );
};

export default GrandTotal;
